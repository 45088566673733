<div class="col-lg-12 p-0" style="height:100%">
  <div class="card overflow-hidden mt-3 mb-3 mt-md-0 mb-md-0 mobile-margin-right" style="max-height:260px">

    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px"  id="header_sticky">
        <h4 class="card-title">Upcoming Events</h4>
      </div>
    </div>

    <div class="pt-2 scroll" style="padding-bottom: 50px;">
      <div class="list-group">
        <div *ngFor="let holiday of eventDayList ;let i=index">
          <div class="list-group-item d-flex pt-0 pb-1 border-0">
            <div class="me-3 me-xs-0">
              <div class="calendar-icon icons" [ngClass]="i%2===0 ? 'bg-pink-transparent' : 'bg-success-transparent'">
                <div class="date_time"> <span class="date"> {{ holiday.date | date:'d' }} </span>
                  <span class="month" style="padding-right:10px"> {{ holiday.date | date: 'MMM' }}</span>
                </div>
              </div>
            </div>
            <div class="ms-1">
              <div class="h5 fs-14 mb-1 pt-3">{{ holiday.reason }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer w-100 d-flex justify-content-end pt-3 position-absolute fixed-bottom">
      <a [routerLink]="['/eventcalendar']" [routerLinkActive]="['highlightActiveLink']"
        (click)="setMenuName('Event Calendar'); navigateEventCalendar()">View All</a>
    </div>
  </div>

</div>