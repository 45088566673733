<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">


                <div class="card">
                    <div class="card-body row">

                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label">Task Name</label>
                                <div>
                                    <input type="text" name="Task Name" class="form-control col-md-12 col-xs-12"
                                        autocomplete="off" placeholder="Enter Task Name" autofocus="true"
                                        [(ngModel)]="taskName" #taskNamee="ngModel" required
                                        [ngClass]="(!taskNamee.valid && taskNamee.touched )? 'is-invalid': '' " />
                                </div>
                                <span class="help-text text-danger"
                                    *ngIf="(!taskNamee.valid && taskNamee.touched)">Enter
                                    Task Name
                                </span>
                            </div>
                        </div>

                        <!-- button flag  -->
                        <div class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                            <div class="form-group label-floating">
                                <label class="form-label">&nbsp;</label>
                                <button *ngIf="addOrUpdate;else updateButton" type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 2vw;margin-left: 2vw;"
                                    (click)="addTaskButton()">
                                    Add
                                </button>
                                <ng-template #updateButton>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                        style="margin-right: 2vw;margin-left: 2vw;" (click)="updateTaskButton()">
                                        Update
                                    </button>
                                </ng-template>
                                <button type="button" class="btn btn-danger w-40"
                                    style="margin-right:1vw;margin-left:1vw" (click)="viewTaskButton()">
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"> </div>

                <div class="empty-div-one" id="navigatedest"></div>

                <!-- </form> -->
                <div class="card" *ngIf="add_view_toggle == 'addTask'">
                    <div class="card-body">

                        <div class="row col-md-12 col-sm-12 col-xs-12">

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating ps-2">
                                    <label class="form-label">Task Name<span style="color: rgb(241, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div>
                                        <input type="text" name="Task Name" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Task Name" autofocus="true"
                                            [(ngModel)]="taskName" #taskNamee="ngModel" required
                                            [ngClass]="(!taskNamee.valid && taskNamee.touched )? 'is-invalid': '' " />
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!taskNamee.valid && taskNamee.touched)">Enter
                                        Task Name
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Task Description<span style="color: rgb(241, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div>
                                        <input type="text" name="annual_name" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Level Name" autofocus="true"
                                            [(ngModel)]="taskDesc" #taskDesce="ngModel" required
                                            [ngClass]="(!taskDesce.valid && taskDesce.touched ) ? 'is-invalid ' : ''" />
                                    </div>
                                    <span class="help-text text-danger" *ngIf="(!taskDesce.valid && taskDesce.touched)">
                                        Enter Task Description
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Task Comment</label>
                                    <div>
                                        <input type="text" name="Job Level" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Job Level Code" autofocus="true"
                                            [(ngModel)]="taskComment" #taskCommente="ngModel"
                                            [ngClass]="(!taskCommente.valid && taskCommente.touched )? 'is-invalid': '' " />
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!taskCommente.valid && taskCommente.touched)">Enter
                                        Task Comment
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Assigned To<span style="color: rgb(241, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div>
                                        <input type="search" class="form-control col-md-12 col-xs-12"
                                            name="Employee Name" id="employeename"
                                            placeholder="Enter Employee Name / ID" [(ngModel)]="query"
                                            autocomplete="off" (input)="getEmployeeByNameOrId(emp)"
                                            list="employeeMasterLucene" autofocus="autofocus">
                                        <datalist id="employeeMasterLucene">
                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                *ngFor="let emp of employeeMasterLucene">
                                            </option>
                                        </datalist>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!taskCommente.valid && taskCommente.touched)">Enter
                                        Assigned To
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Due Date<span style="color: rgb(241, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div>
                                        <input class="form-control col-md-12 col-sm-12 col-xs-12" type="date"
                                            name="Date" autocomplete="off" autofocus="true" [disabled]="false"
                                            [(ngModel)]="dueDate" required #datee="ngModel"
                                            [ngClass]="(!datee.valid && datee.touched) ? 'is-invalid' : ''" />
                                    </div>
                                    <span class="help-text text-danger" *ngIf="(!datee.valid && datee.touched)">Enter
                                        Date
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="actionBar pe-4 ps-4" *ngIf="add_view_toggle == 'addTask'">
                    <button class="btn btn-success" type="submit" *ngIf="addOrUpdate" (click)="saveTask()">Save</button>
                    <button *ngIf="addOrUpdate" class="btn btn-danger ms-2 " type="clear"
                        (click)=" clear()">Clear</button>
                </div>

                <!-- view job list  -->

                <div class="row card-list-margin">
                    <div class="col-md-12 card-list1-margin">
                        <div class="card" *ngIf="add_view_toggle == 'viewTask'">

                            <div class="card-body">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                            <h3 class="card-title">Task List</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive"
                                                style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                                <div class="scrollsearchresults" id="scroll" infiniteScroll
                                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                                    [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                                    id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fs-6 ">Sl.No</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Task Name
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Task Description
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Comment
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Assigned To</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Assigned Date</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Due Date</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!-- style="max-height: 75vh; overflow: scroll;" -->
                                                            <tr *ngFor="let td of taskMasterDetail; let i = index">
                                                                <td>{{i+1}}</td>
                                                                <td
                                                                    style="min-width: 10vw;max-width: 12vw;text-wrap: wrap;">
                                                                    {{td.title}}
                                                                </td>
                                                                <td
                                                                    style="min-width: 12vw;max-width: 20vw;text-wrap: wrap;">
                                                                    {{td.description == undefined || null ? '' :
                                                                    td.description}}</td>
                                                                <td
                                                                    style="min-width:6vw;max-width: 12vw;text-wrap: wrap;">
                                                                    {{td.comment == undefined || null ? '' :
                                                                    td.comment}}</td>
                                                                <td>{{td.employeeName}}</td>
                                                                <td>{{td.assignedTime | date:'mediumDate' }}</td>
                                                                <td>{{td.duedate | date:'mediumDate' }}</td>
                                                                <td>
                                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                        data-method="edit" title="Edit"
                                                                        (click)="editTask(td)">
                                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                            data-original-title="edit"></i>
                                                                    </button>

                                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(td.id)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Task Detail will be deleted permanently. Are you sure you want to
                        continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteTask()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeTask()">Cancel</button>
            </div>

        </div>
    </div>
</div>