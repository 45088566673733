import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { Employeesuspensioninfo } from '../models/Employeesuspensioninfo';
import { EmployeeMasterservice } from '../services/employeemaster.service';

@Component({
  selector: 'app-employee-suspension',
  templateUrl: './employee-suspension.component.html',
  styleUrls: ['./employee-suspension.component.css']
})
export class EmployeeSuspensionComponent implements OnInit {
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  employeeMasterLucene: EmployeeMaster[];
  uniqueemployeeid: String;
  query: any;
  limitPara: any;
  offsetPara: any;
  queryName: any;
  insertemployeesuspension;
  add_view_toggle: string = 'searchempsuspension';
  selectedempid: number;
  suspension: any;
  constructor(private empLeaveService: EmployeeLeaveService, private employeeMasterservice: EmployeeMasterservice) { }

  ngOnInit(): void {
    this.insertemployeesuspension = new Employeesuspensioninfo()
    this.add_view_toggle = '';
    this.getemployeesuspension();
    this.getEmployeeByNameOrId();
  }

  getEmployeeByNameOrId(callback?: () => void) {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.uniqueemployeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
      if (callback) callback();
      return; // Exit early if the query is too short
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);

          if (data.employeeMasterData && data.employeeMasterData.length > 0) {
            this.employeeMasterLucene = data.employeeMasterData;
            this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
          } else {
            // No employees found
            this.employeeMasterLucene = [];
          }
          if (callback) callback();
        },
        error => {
          console.log("Error ", error);
          this.alertmessages("An error occurred while searching for the employee", "danger");
          if (callback) callback();
        }
      );
    }
  }

  searchEmployee() {
    this.getEmployeeByNameOrId();
    if (!this.query || this.query.trim().length === 0) {
      // Trigger the error message
      this.alertmessages("Please Enter Employee Name or Unique Employee ID", "danger");
      return;
    }

    // Call the search and handle the result in a callback
    this.getEmployeeByNameOrId(() => {
      if (this.employeeMasterLucene.length === 0) {
        // Trigger error message when no employee is found
        this.alertmessages("No employee found with the provided ID or Name.", "danger");
      } else {
        // Proceed with normal behavior
        this.add_view_toggle = 'searchempsuspension';
      }
    });
  }


  limitpara() {
    this.limitPara = 0;
  }

  submit() {
    // Validate mandatory fields
    if (
      !this.insertemployeesuspension.fromdate ||
      !this.insertemployeesuspension.todate ||
      !this.query ||
      !this.insertemployeesuspension.reason
    ) {
      this.alertmessages("Please fill all required fields.", "danger");
      return;
    }

    // Perform employee search and wait for completion
    this.getEmployeeByNameOrId(() => {
      if (this.employeeMasterLucene.length === 0) {
        // Trigger error message when no employee is found
        this.alertmessages("No employee found with the provided ID or Name.", "danger");
        return; // Exit without saving
      }

      // Validate dates
      const fromDate = new Date(this.insertemployeesuspension.fromdate);
      const toDate = new Date(this.insertemployeesuspension.todate);
      if (toDate < fromDate) {
        this.alertmessages("To Date cannot be prior to From Date.", "danger");
        return;
      }

      // Check if a suspension already exists for the same employee and the same date range
    const existingSuspension = this.suspension.some(susp => {
      const existingFromDate = new Date(susp.fromdate);
      const existingToDate = new Date(susp.todate);
      // Check if it's the same employee and date overlap
      return susp.uniqueEmployeeId === this.selectedempid &&
        (fromDate <= existingToDate && toDate >= existingFromDate);
    });

    if (existingSuspension) {
      this.alertmessages("Suspension already exists for this employee in this date", "danger");
      return;
    }


      // Save suspension data
      console.log("save ", this.insertemployeesuspension);
      console.log("empid ", this.insertemployeesuspension.uniqueEmployeeId);
      console.log("fromdate ", this.insertemployeesuspension.fromdate);
      console.log("todate:", this.insertemployeesuspension.todate);
      console.log("Reason:", this.insertemployeesuspension.reason);

      this.insertemployeesuspension.uniqueEmployeeId = this.selectedempid;
      this.employeeMasterservice.saveEmployeeSuspension(this.insertemployeesuspension).subscribe(
        (data) => {
          console.log(data);
          this.add_view_toggle = '';
          this.clear();
          this.alertmessages("Employee Suspension Applied successfully", "success");
        },
        (error) => {
          console.log(error);
          this.alertmessages("Please Try Again", "danger");
        }
      );
    });
  }



  clear() {
    this.insertemployeesuspension.fromdate = "";
    this.insertemployeesuspension.todate = "";
    this.insertemployeesuspension.reason = "";
    this.add_view_toggle = '';
    this.query = '';
  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  getemployeesuspension() {
    this.employeeMasterservice.getemployeesuspension().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.suspension = data.employeesuspensioninfo;
        console.log("List of all the suspension available :", this.suspension);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
