<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">
        <div class="alertcomp">

        </div>
        <div class="page-header d-xl-flex d-block">
            <!-- <div class="page-leftheader">
                <div class="page-title">Apply Attendance</div>
            </div> -->
            <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <a [routerLink]="['/employeeattendencesummary']" class="btn btn-primary me-3"
                            (click)="setMenuName('Swipe details'); $event.preventDefault()">Swipe details</a>
                        <!-- <button class="btn btn-primary me-3" (click)="EnableLocation1()">Enable Location 1</button> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-body">

                        <div class="btn-list text-center ">
                            <div>
                                <img loading="lazy" src="./../../assets/img/clock.JPG" id="clockIN" data-clockin="1"
                                    class=" clock" alt="clockin" style=" width: 180px;">
                                <img loading="lazy" src="./../../assets/img/Clock-move-FINAL.gif" id="clockOut"
                                    data-clockout="2" style=" width: 180px;" class="clock displayNone" alt="clockout">
                            </div>

                            <div class="countdowntimer mt-0">
                                <span id="clocktimer2" class="border-0"></span>
                                <label class="form-label">Current Time</label>
                            </div>

                            <div class="countdowntimer mt-0" *ngIf="taskMasterDetail.length>0">
                                <div class="col-md-12 col-sm-12 col-xs-12 mb-4">
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="taskList" class="form-control" name="taskList"
                                                placeholder="Select Task" [(ngModel)]="taskid" autocomplete="off"
                                                autofocus="autofocus">

                                                <option value="" selected="selected">Select Task</option>
                                                <option value={{data.id}} *ngFor="let data of taskMasterDetail">
                                                    {{data.title}} - {{data.description }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="btn-group  mb-2">

                                <ng-container *ngIf="captureAttendanceForEmployee; else captureClockInButton">
                                    <button class="btn dropdown bgcolors "
                                        (click)="checkAttendanceCaptureWithImage($event,'IN')" data-bs-toggle="modal"
                                        data-bs-target="#swipeModal">
                                        Clock In <span class="caret"></span>
                                    </button>
                                </ng-container>

                                <ng-template #captureClockInButton>
                                    <button class="btn dropdown bgcolors "
                                        (click)="checkAttendanceCaptureWithImage($event,'IN')">
                                        Clock In <span class="caret"></span>
                                    </button>
                                </ng-template>

                            </div>

                            <div class="btn-group ms-2 mb-2">

                                <ng-container *ngIf="captureAttendanceForEmployee; else captureClockOutButton">
                                    <button class="btn dropdown bgcolors "
                                        (click)="checkAttendanceCaptureWithImage($event,'OUT')" data-bs-toggle="modal"
                                        data-bs-target="#swipeModal">
                                        Clock Out <span class="caret"></span>
                                    </button>
                                </ng-container>

                                <ng-template #captureClockOutButton>
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImage($event,'OUT') ">
                                        Clock Out<span class="caret"></span>
                                    </button>
                                </ng-template>

                                <ul class="dropdown-menu" role="menu" *ngIf="break.length!==0">
                                    <li class="dropdown-plus-title">
                                        Breaks
                                        <b class="fa fa-angle-up" aria-hidden="true"></b>
                                    </li>
                                    <li style="cursor: pointer;" *ngFor="let item of break">
                                        <a data-breakid="{{item.id}}"
                                            (click)="save_employee_attendences($event,'OUT')">{{item.breaks}}</a>
                                    </li>

                                    <!-- <li class="divider"></li>
                                    <li><a  href="javascript:void(0);">Separated link</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Swipe Info as on</h4>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive" style="height: 258px;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow">
                                    <tr class="rowcolors">
                                        <!-- <th class="border-bottom-0">Date</th> -->
                                        <!-- <th class="border-bottom-0">Status</th> -->
                                        <th class="border-bottom-0">Sl No</th>
                                        <th class="border-bottom-0">Clock IN/OUT</th>
                                        <th class="border-bottom-0">Time</th>
                                        <th class="border-bottom-0" *ngIf="taskMasterDetail.length>0">Title</th>
                                        <th *ngIf="captureAttendanceForEmployee" class="border-bottom-0"></th>
                                        <!-- <th class="border-bottom-0">Progress</th> -->
                                        <!-- <th class="border-bottom-0">Hours</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                        <!-- <td>{{swipe}}</td> -->
                                        <!-- <td><span class="badge badge-success">Present</span></td> -->
                                        <td>{{i+1}}</td>
                                        <td>{{ swipe.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                        <td>{{ swipe.swipetime| date: 'shortTime' }}</td>
                                        <td *ngIf="taskMasterDetail.length>0" style="text-wrap: wrap;">{{
                                            swipe.taskTitle }}</td>
                                        <!-- <td> {{swipe.capturedPath}} - {{swipe.fileName}}</td> -->
                                        <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                        <!-- <td>09h :10 mins</td> -->
                                        <td *ngIf="captureAttendanceForEmployee">
                                            <button class="btn btn-success plr"
                                                *ngIf="swipe.capturedPath!= undefined && swipe.capturedPath!=null"
                                                data-bs-toggle="modal" data-bs-target="#additionalModalSwipe"
                                                (click)="getPreview(swipe)">
                                                <i class="si si-picture" data-bs-toggle="tooltip"
                                                    data-original-title="download"></i>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="emp_attendance_intermediatelist.length !==0">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Regularized Attendance Summary</h4>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive">
                            <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false">

                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">
                                            <!-- <th class="border-bottom-0">Date</th> -->
                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">Date</th>
                                            <th class="border-bottom-0">Clock IN/OUT</th>
                                            <th class="border-bottom-0">Time</th>
                                            <th class="border-bottom-0">Status</th>
                                            <th class="border-bottom-0">Approved By</th>

                                            <!-- <th class="border-bottom-0">Progress</th> -->
                                            <!-- <th class="border-bottom-0">Hours</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let emp of emp_attendance_intermediatelist ; let i=index">
                                            <!-- <td>{{swipe}}</td> -->
                                            <!-- <td><span class="badge badge-success">Present</span></td> -->
                                            <td>{{i+1}}</td>
                                            <td>{{emp.stime | date: 'mediumDate'}}</td>
                                            <td>{{ emp.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                            <td>{{ emp.swipetime | date: 'shortTime' }}</td>
                                            <!-- <td>
                                                <span *ngIf="emp.status === 'PENDING'"
                                                    class="badge badge-warning">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'APPROVED'"
                                                    class="badge badge-success">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'REJECTED'"
                                                    class="badge badge-danger">{{emp.status}}</span>
                                            </td> -->
                                            <td>
                                                <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                   emp.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                   emp.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                   emp.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                   emp.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                   emp.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                    'bg-custom-warning text-custom-dark'
                                                  " class="badge">
                                                    {{ emp.status }}
                                                </span>
                                            </td>

                                            <td>{{ emp.updatedBy}}</td>
                                            <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                            <!-- <td>09h :10 mins</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Select the type of break</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="swipeModal" tabindex="-1" aria-labelledby="swipeModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="swipeModalLabel" style="font-size: 20px;">Capture Attendance</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="modal-body" *ngIf="capturedImages.length == 0">
                    <video id="cameraStream" width="100%" height="auto" autoplay></video>
                </div>
                <ng-container *ngIf="capturedImages.length>0">
                    <h5 class="modal-title" style="font-size: 17px;">Captured Image</h5>
                    <div class="modal-body pb-5" *ngFor="let images of capturedImages">
                        <img [src]="images" width="100%" height="auto">
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="stopMediaStream()">Close</button>

                <ng-container *ngIf="capturedImages.length > 0; else captureButton">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="saveImageAndSwipe()">OK</button>
                </ng-container>

                <ng-template #captureButton>
                    <button type="button" class="btn btn-primary" (click)="captureAndStopStream()">Capture</button>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="additionalModalSwipe" tabindex="-1" aria-labelledby="additionalModalLabelSwipe"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Captured Image</h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="modal-body">
                        <img width="100%" height="auto" alt="img"
                            [src]="url || './../../../assets/template_assests/images/users/1.jpg'">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>