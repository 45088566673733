import { Component, OnInit } from '@angular/core';
import { EmployeeLeaveInfo } from '../models/employee-leave-info.model';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeLeaveOverview } from '../models/employee-leave-overview.model';
import { AlertService, SessionService } from 'src/app/services';

import { ApexPlotOptions } from "ng-apexcharts";
import { CommonService } from '../services/common.service';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';

import {
	ApexNonAxisChartSeries,
	ApexResponsive,
	ApexChart,
	ApexFill,
	ApexDataLabels,
	ApexLegend
} from "ng-apexcharts";
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Route, Router } from '@angular/router';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   responsive: ApexResponsive[];
//   labels: any;
//   fill: ApexFill;
//   legend: ApexLegend;
//   dataLabels: ApexDataLabels;
// };

declare var $: any;

@Component({
	selector: 'app-employee-leave',
	templateUrl: './employee-leave.component.html',
	styleUrls: ['./employee-leave.component.css']
})
export class EmployeeLeaveComponent implements OnInit {
	chartSeries: ApexNonAxisChartSeries;
	chartDetails: ApexChart;
	chartLabels: any[]
	chartDataLabels: ApexDataLabels;
	// chartTitle: ApexTitleSubtitle;
	chartLegend: ApexLegend;
	plotOptions: ApexPlotOptions
	chartColor: any[]
	colorList: any[]
	chartColorsMap = new Map();
	// 	@ViewChild("chart") chart: ChartComponent | any;
	//   public chartOptions!: Partial<ChartOptions> | any;
	showDelBtn = false;
	//   uniqueEmployeeId = 10001; 
	uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
	// empLeaveInfo = new EmployeeLeaveInfo("2022-06-14","2022-06-17","CL","F");
	empLeaveInfo = new EmployeeLeaveInfo(-1, -1, "", "", "", "", "", "");
	totalLeaveCount: number = 0;
	totalLeaveDates = [];
	leaveTypesList = [
		{ leaveType: "", leaveName: "", interveningHolidays: "" }
	]

	leaveDatesList = [
		{ halfFullIndic: "half", leaveDay: "Half Day Leave" },
		{ halfFullIndic: "single", leaveDay: "Single Leave" },
		{ halfFullIndic: "multiple", leaveDay: "Multiple Leaves" }
	]

	leaveTypeMap = new Map();
	employeeLeaveName: string[] = []
	employeeLeaveValue: number[] = []
	employeeLeaveOverview: EmployeeLeaveOverview[] = [new EmployeeLeaveOverview("", -1)]
	employeeLeaveSummary: EmployeeLeaveApplied[] = []
	empleaveCarryforward: any[] = []
	deleteEmployeeLeave: EmployeeLeaveApplied
	leaveForPastDate: any;
	firstDay: Date;
	configKey: string = 'leaveForPastDate';
	todayDate: any;

	interleaveHoliday: boolean = false;
	annualhoildaylist: AnnualHoliday[];

	leaveBalance_Count: any;
	totalleave_count: any;
	suspension: any;

	constructor(private empLeaveService: EmployeeLeaveService, private alertService: AlertService,
		private session: SessionService, private datepipe: DatePipe, private router: Router, private commonService: CommonService, private titleUpperCase: TitleCasePipe) {
		this.todayDate = new Date();
	}

	ngOnInit(): void {
		console.log(" ")
		console.log("todayDate is ", this.todayDate);
		console.log("");
		this.getsuspensionlist();
		// this.colorList = ['#33c5ff', '#f7284a','#01c353','#fe7700','#c3c001','#33e0ff','#6574cd','#ef4eb8','#ecb403','#17a2b8'];
		this.colorList = this.empLeaveService.getChartColors();
		this.initializeChartOptions();
		this.getEmployeeLeaveAppliedSummary()
		this.getLeaves();
		// this.setLeaveMap();
		var info = this.empLeaveInfo;
		// var handle = this.handleOnChange.bind(this);
		var calculate = this.calculateLeave.bind(this);
		// Get leave for a past date Limit from Adminconfig
		this.getLeaveForPastDate()
		this.getannualHoildayList()
		$('#daterange-categories').on('change', function () {
			document.getElementById('leavetypes')?.setAttribute("disabled", "true")
			let indic = info.halfFullIndic;

			$('.leave-content').hide();

			if (indic === "half" || indic === "single") {
				console.log("chk indic in self ", indic);

				$('#single').show();
				document.getElementById('singledate')?.removeAttribute("disabled")
			} else if (indic === "multiple") {

				$('#multiple').show();
				document.getElementById('multipledate')?.removeAttribute("disabled")
			}
		});

		$('#singledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("Single Target ",ev.target.value);
			// console.log("Single Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("Single End ",picker.endDate.format('YYYY-MM-DD'));
			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("empLeaveInfo  for singledate:",info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")
			// handle()
			calculate()
		});

		$('#multipledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// console.log("multi target ",ev.target.value);
			// console.log("multi Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("multi End ",picker.endDate.format('YYYY-MM-DD'));
			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("empLeaveInfo for multipledate:",info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")
			// handle()
			calculate()

		});

		this.checkInterveneLeave();
	}

	setChartColorMap() {
		this.employeeLeaveName.forEach((leave, i) => {
			this.chartColorsMap.set(leave, this.colorList[i])
		});
		// console.log("Color map ",this.chartColorsMap);
	}

	initializeChartOptions(): void {
		this.chartSeries = this.employeeLeaveValue;

		this.chartColor = this.colorList;

		this.chartDetails = {
			type: 'donut',
			toolbar: {
				show: true
			},
		}

		this.chartLabels = this.employeeLeaveName;

		this.chartDataLabels = {
			enabled: false
		}

		this.plotOptions = {
			pie: {
				startAngle: 0,
				endAngle: 360,
				expandOnClick: true,
				offsetX: 0,
				offsetY: 0,
				customScale: 1,
				dataLabels: {
					offset: 0,
					minAngleToShowLabel: 10
				},
				donut: {
					size: '80%',
					background: 'transparent',
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '20px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 600,
							color: undefined,
							offsetY: -10,
							formatter: function (val) {
								return val
							}
						},
						value: {
							show: true,
							fontSize: '20px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 600,
							color: '#3366ff',
							offsetY: 16,
							formatter: function (val) {
								return val
							}
						},
						total: {
							show: true,
							showAlways: false,
							label: 'Total',
							fontSize: '20px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 600,
							color: '#373d3f',
							formatter: function (w) {
								return w.globals.seriesTotals.reduce((a, b) => {
									return a + b
								}, 0)
							}
						}
					}
				},
			}
		}

		this.chartLegend = {
			position: "bottom",
			horizontalAlign: 'left',
			show: false
		}
	}

	calculateLeave() {
		console.log("calculateLeave function");
		this.totalLeaveCount = 0;
		const start = new Date(this.empLeaveInfo.fromDate);
		const end = new Date(this.empLeaveInfo.toDate);

		for (let day = start; day <= end; day.setDate(day.getDate() + 1)) {
			console.log(day, day.getDay(), this.interleaveHoliday);
			console.log("interleaveHoliday:", this.interleaveHoliday);
			if (!this.interleaveHoliday) {
				// Skip Saturdays and Sundays
				if (day.getDay() === 0 || day.getDay() === 6) {
					console.log("Skipping weekend:", day);
					continue;
				}
				// Exclude annual holidays
				if (!this.isAnnualHoliday(day)) {
					console.log("Counting leave day:", day);
					++this.totalLeaveCount;
				}
			}
			else {
				console.log("if intervene true Counting leave day:", this.totalLeaveCount);
				++this.totalLeaveCount;
			}
		}

		console.log("Total leave count before half/full check:", this.totalLeaveCount);

		// Handle half-day leave
		if (this.empLeaveInfo.halfFullIndic == 'half') {
			this.totalLeaveCount = 0.5;
			console.log("Half-day leave, totalLeaveCount =", this.totalLeaveCount);
		}

		console.log("Final leave count:", this.totalLeaveCount);
	}

	isAnnualHoliday(date: Date): boolean {
		console.log("is annual hoiliday", date)
		console.log(this.annualhoildaylist);

		// Check if the current date is an annual holiday
		return this.annualhoildaylist.some(holiday => {
			const holidayDate = new Date(holiday.date);
			console.log(holidayDate);

			console.log(date.getMonth() === holidayDate.getMonth() && date.getDate() === holidayDate.getDate());
			return date.getMonth() === holidayDate.getMonth() && date.getDate() === holidayDate.getDate();
			return date.toISOString().split('T')[0] === holidayDate.toISOString().split('T')[0];
		});
	}

	onSubmit() {

		console.log(" on submit 1 ");

		this.empLeaveInfo.uniqueEmployeeId = this.uniqueEmployeeId
		let today = new Date();
		this.empLeaveInfo.appliedDate = today.toISOString().substring(0, 10);
		console.log("Submit Data ", this.empLeaveInfo);
		if (this.empLeaveInfo.halfFullIndic == "") {
			alert("Please Leave Date Type field..")
			return;
		}
		if (this.empLeaveInfo.fromDate == "" || this.empLeaveInfo.toDate == "") {
			alert("Please Select Leave Date")
			return;
		}
		if (this.empLeaveInfo.leaveType == "") {
			alert("Please Select Leave Type")
			return;
		}
		if (this.empLeaveInfo.reason == "") {
			alert("Please Select Reason")
			return;
		}

		// Check if the selected date is a Sunday
		const selectedDate = new Date(this.empLeaveInfo.fromDate);

		console.log(" on submit 2 ");
		if (selectedDate.getDay() === 0 && (new Date(this.empLeaveInfo.fromDate).getTime() - new Date(this.empLeaveInfo.toDate).getTime()) === 0) {
			console.log(this.interleaveHoliday);

			if (selectedDate.getDay() === 0) {
				this.alertmessages("Leave can not be Applied For Sunday", "danger")
				return;
			}
		}
		console.log(this.empLeaveInfo.fromDate, this.empLeaveInfo.toDate);


		console.log(" on submit 3 ");
		if ((new Date(this.empLeaveInfo.fromDate).getTime() - new Date(this.empLeaveInfo.toDate).getTime()) !== 0) {

			let isinvalid = this.iterateOverDates(this.empLeaveInfo.fromDate, this.empLeaveInfo.toDate);
			if (isinvalid) {
				// this.alertmessages("Can not Apply for the Leave as it is Sunday", "danger");
				// return;
			}
			// 	return;
		}

		// let date = new Date(this.empLeaveInfo.fromDate)
		// if (date.getDay() === 0) {
		// 	this.alertmessages("Can not Apply for the Leave as it is Sunday", "danger");
		// 	return;
		// }
		// Loop through the suspension list and check if the leave period overlaps with any suspension period
		// Check if the employee is suspended during the leave period
		const suspendedPeriod = this.suspension.find(susp => {
			// Convert suspension dates to Date objects
			let suspensionStart = new Date(susp.fromdate);
			let suspensionEnd = new Date(susp.todate);

			// Convert leave dates to Date objects
			let leaveStart = new Date(this.empLeaveInfo.fromDate);
			let leaveEnd = new Date(this.empLeaveInfo.toDate);

			// Check if leave period overlaps with suspension period and match uniqueEmployeeId
			return suspensionStart <= leaveEnd &&
				suspensionEnd >= leaveStart &&
				susp.status.toLowerCase() === "approved" &&
				susp.uniqueEmployeeId === this.empLeaveInfo.uniqueEmployeeId;
		});

		if (suspendedPeriod) {
			const suspensionStart = new Date(suspendedPeriod.fromdate).toLocaleDateString();
			const suspensionEnd = new Date(suspendedPeriod.todate).toLocaleDateString();
			// Include the employee's name and suspension dates in the error message
			this.alertmessages(`You are suspended from ${suspensionStart} to ${suspensionEnd} and cannot apply for leave during this period.`, "danger");
			return;
		}

		this.empLeaveInfo.reason = this.empLeaveInfo.reason.trim();

		// console.log(this.empLeaveInfo, "emp leave info ", this.interleaveHoliday)

		// console.log(this.empLeaveInfo, "emp leave info ", this.interleaveHoliday)


		console.log(" on submit  4 ");
		console.log(" ");
		console.log(" leaveBalance_Count ", this.leaveBalance_Count);
		console.log(" totalLeaveCount ", this.totalLeaveCount);

		this.empLeaveService.saveEmployeeLeave(this.empLeaveInfo).subscribe(
			data => {

				this.getEmployeeLeaveAppliedSummary();
				this.onClear()
				this.alertmessages("Successfully Submitted", "success")

				// this.reloadthescreen();

			},
			error => {
				const response = error.error;

				if (response.errorMessages != null && response.errorMessages.length !== 0) {
					let err: string = response.errorMessages[0]['key'];
					if (err.toLocaleLowerCase() === "AlreadyExistsException".toLocaleLowerCase()) {
						this.alertmessages("A leave request has already been made for the selected date.", "danger")
					}
					// else {
					// 	this.alertmessages("Can't Apply for Leave", "danger")
					// }
				}
				// } else {
				// 	this.alertmessages("Can't Apply for Leave", "danger")
				// }
				this.onClear()
				// this.reloadthescreen();
			}
		)
	}

	getLeaves() {
		this.empLeaveService.getAllLeaves().subscribe(
			data => {
				console.log(data.leaveMasters)
				this.leaveTypesList = data.leaveMasters
				this.setLeaveMap();
				console.log("Data Leaves ", this.leaveTypesList);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	onChangeDays(day: any) {
		console.log("  ");
		console.log("onChangeDays ", day);
		this.empLeaveInfo.halfFullIndic = day
		console.log("this.empLeaveInfo.halfFullIndic  ", this.empLeaveInfo.halfFullIndic);

	}

	enableDatesField() {
		document.getElementById('leavetypes')?.setAttribute("disabled", "true")
		let indic = this.empLeaveInfo.halfFullIndic;

		$('.leave-content').hide();

		if (indic === "half" || indic === "single") {
			$('#single').show();
			document.getElementById('singledate')?.removeAttribute("disabled")
		} else if (indic === "multiple") {

			$('#multiple').show();
			document.getElementById('multipledate')?.removeAttribute("disabled")
		}
	}

	onChangeLeaveTypes(leaveType: any) {
		console.log("Leave Type ", leaveType);
		// console.log("Emp Leave Type ",this.empLeaveInfo);
		this.empLeaveInfo.leaveType = leaveType

		console.log(this.leaveTypesList);
		this.leaveTypesList.forEach(list => {
			if (list.leaveType === leaveType) {
				if (list.interveningHolidays) {
					this.interleaveHoliday = true; // sunday should be considered
				} else {
					this.interleaveHoliday = false; // not considered
				}

			}
		})

		this.iterateOverDates(this.empLeaveInfo.fromDate, this.empLeaveInfo.toDate)
		this.calculateLeave()

		// let ele = document.getElementById('leavetypesc');
		// const childele = document.createElement('div')
		// childele.innerText = this.leaveTypeMap.get(leaveType)
		// ele.append(childele)
		// console.log(ele,childele)
		// childele.innerText = 
		// this.handleOnChange();
		document.getElementById('reason')?.removeAttribute("disabled")

		this.getleaveBalance()
	}

	showTable = false
	getEmployeeLeaveAppliedSummary() {
		this.empLeaveService.getEmployeeLeaveAppliedSummary(this.uniqueEmployeeId).subscribe(
			data => {

				this.employeeLeaveSummary = data.employeeLeaveAppliedInfo

				this.employeeLeaveSummary.forEach(item => {
					item.leaveStatus = this.titleUpperCase.transform(item.leaveStatus);
				})

				if (this.employeeLeaveSummary.length > 0) {
					this.showTable = true
				}
				// console.log("Employee Leave Summary ", this.employeeLeaveSummary );
				// this.scrollLength()
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
		console.warn(this.leaveTypeMap, "leave map")
		this.getEmployeeLeaveOverview()
	}

	getLeaveName(leaveType: any) {
		console.warn(leaveType, "leave type", this.leaveTypeMap.get(leaveType))
		return this.leaveTypeMap.get(leaveType)
	}

	onDeleteHandler(empLeaveApplied: any) {
		this.deleteEmployeeLeave = empLeaveApplied;
		$('#showAlertVer').modal('toggle');
	}


	closeMRQModal() {
		$('#showAlertVer').modal('toggle');
	}

	confirmLeaveDelete() {
		// console.log("DELETE LEAVE 1",this.deleteEmployeeLeave);

		this.empLeaveService.deleteEmployeeLeaveApplied(this.deleteEmployeeLeave).subscribe({
			next: (data) => {
				this.alertmessages(data.successMessages[0]['key'], "success")
				this.getEmployeeLeaveAppliedSummary();
			},
			error: (error) => {
				console.log("Error ", error);
				this.alertmessages("Not Deleted", "danger")
			},
			complete: () => {
				this.closeMRQModal();
			}
		})
	}

	showDeleteBtn(status) {
		return (status !== 'LeaveCancelRejected') || (status !== 'LeaveCancelPending');
	}

	getFirstDayPrevMonth() {
		const date = new Date();
		const prevMonth = date.getMonth() - 1;
		const firstDay = 1;
		return new Date(date.getFullYear(), prevMonth, firstDay);
	}

	onChangeReason(reason: any) {
		// console.log("REason ",reason);
		this.empLeaveInfo.reason = reason
		// console.log("Emp Leaves ",this.empLeaveInfo);
	}

	getEmployeeLeaveOverview() {
		this.empLeaveService.getEmployeeLeaveOverview(this.uniqueEmployeeId).subscribe(
			data => {
				this.employeeLeaveOverview = data.leaveTypeDtos
				this.setEmployeeLeaveOverview(this.employeeLeaveOverview)
				// console.log("Emp LEave Overview", this.employeeLeaveOverview );
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	showChart = false
	setEmployeeLeaveOverview(empLeaveOverview: EmployeeLeaveOverview[]) {
		empLeaveOverview.forEach(view => {
			if (this.getLeaveName(view.leaveType)) {
				this.employeeLeaveName.push(this.getLeaveName(view.leaveType))
				this.employeeLeaveValue.push(view.leaveTypeCount);

			}

		})
		this.colorList = this.colorList.slice(0, this.employeeLeaveName.length)
		this.setChartColorMap()
		this.showChart = true
		console.log("COLOR LISTTTTTT ", this.colorList);
		console.log("Name ", this.employeeLeaveName);
		console.log("Val ", this.employeeLeaveValue);
	}

	initDatePicker() {
		var info = this.empLeaveInfo;
		// var handle = this.handleOnChange.bind(this);
		var calculate = this.calculateLeave.bind(this);
		$('input[name="singledaterange"]').daterangepicker({
			singleDatePicker: true,
			// minDate: new Date(),
			minDate: this.firstDay,
			startDate: new Date(),
			endDate: new Date(),

		})
		$('input[name="daterange"]').daterangepicker({
			// minDate: new Date(),
			minDate: this.firstDay,
			opens: 'left',
			startDate: new Date(),
			endDate: new Date(),
		})
		$('#daterange-categories').on('change', function () {
			document.getElementById('leavetypes')?.setAttribute("disabled", "true")
			let indic = info.halfFullIndic;

			$('.leave-content').hide();

			if (indic === "half" || indic === "single") {
				$('#single').show();
				document.getElementById('singledate')?.removeAttribute("disabled")
			} else if (indic === "multiple") {
				$('#multiple').show();
				document.getElementById('multipledate')?.removeAttribute("disabled")
			}

		});

		$('#singledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("Single Target ",ev.target.value);
			// console.log("Single Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("Single End ",picker.endDate.format('YYYY-MM-DD'));
			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("empLeaveInfo  for singledate:",info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")
			// handle()
			calculate()
		});

		$('#multipledate').on('apply.daterangepicker', function (ev: any, picker: any) {
			// console.log("multi target ",ev.target.value);
			// console.log("multi Start ",picker.startDate.format('YYYY-MM-DD'));
			// console.log("multi End ",picker.endDate.format('YYYY-MM-DD'));
			info.fromDate = picker.startDate.format('YYYY-MM-DD');
			info.toDate = picker.endDate.format('YYYY-MM-DD');
			// console.log("empLeaveInfo for multipledate:",info);
			document.getElementById('leavetypes')?.removeAttribute("disabled")


			let datepickerele = <HTMLElement>document.querySelector('.daterangepicker.opensright');
			datepickerele.style.left = '280 !important';
			datepickerele.classList.add('showcaltoleft')
			// handle()
			calculate()
		});
	}

	onClear() {
		this.totalLeaveCount = 0;
		this.empLeaveInfo = new EmployeeLeaveInfo(-1, -1, "", "", "", "", "", "");
		document.getElementById('singledate')?.setAttribute("disabled", "true");
		document.getElementById('multipledate')?.setAttribute("disabled", "true");
		document.getElementById('leavetypes')?.setAttribute("disabled", "true")
		document.getElementById('reason')?.setAttribute("disabled", "true")
		console.log("This is the fiest date", this.firstDay)
		this.initDatePicker()
	}

	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}

	getleaveBalance() {
		let uempid = this.session.getempid();
		let date = new Date();
		this.empLeaveInfo.appliedDate = date.toISOString().substring(0, 10);
		let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		let firstDayOfPreviousMonth = new Date(firstDay.getFullYear(), firstDay.getMonth() - 1, 1); //first day of previous month
		let leavetype = this.empLeaveInfo.leaveType;
		let indic = this.empLeaveInfo.halfFullIndic;
		console.log("🚀 ~ file: employee-leave.component.ts ~ line 609 ~ EmployeeLeaveComponent ~ getleaveBalance ~ firstDay", firstDay)
		// let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		// return;

		//check opening leave-balance

		this.empLeaveService.getleavebalance(Number(uempid), leavetype, this.datepipe.transform(this.empLeaveInfo.fromDate, 'yyyy-MM-dd')).subscribe({
			next: (res) => {
				this.leaveBalance_Count = res.openingleaveBalance;

				console.log(" get leave balance function  ");
				console.log(" ");
				console.log(" leaveBalance_Count ", this.leaveBalance_Count);
				console.log(" totalLeaveCount ", this.totalLeaveCount);
				console.log(" this.leaveBalance_Count < this.totalLeaveCount ", this.leaveBalance_Count < this.totalLeaveCount);
				console.log(" this.leaveBalance_Count > this.totalLeaveCount ", this.leaveBalance_Count > this.totalLeaveCount);
				console.log(" this.leaveBalance_Count == this.totalLeaveCount ", this.leaveBalance_Count == this.totalLeaveCount);

				let count = this.calculateLeave();
				if (this.leaveBalance_Count == 0) {
					this.alertmessages(" Can't apply for leave. You have no sufficient leaves for this leave type.", "danger")
					this.onClear()
				} else if (this.leaveBalance_Count < this.totalLeaveCount) {
					this.alertmessages(" Can't apply for leave. You have no sufficient leaves for this leave type.", "danger")
					this.onClear()
				}

			}, error: (err) => {
				console.log(err)

			}

		})
	}

	iterateOverDates(fromDate, toDate) {
		// Convert input strings to Date objects
		const startDate = new Date(fromDate);
		const endDate = new Date(toDate);

		// Check if the start date is greater than the end date
		if (startDate > endDate) {
			console.error("Invalid date range: fromDate should be before toDate");
			return;
		}

		// Iterate over the dates
		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			// Do something with the current date (e.g., print it)
			console.log(currentDate.toISOString().split('T')[0]);
			console.log(this.interleaveHoliday)
			// Move to the next day
			// if(!this.interleaveHoliday){
			// 	this.alertmessages(`Selected date : ${currentDate.toISOString().split('T')[0]}`,"error");
			// }
			console.log(currentDate.getDay())
			if (currentDate.getDay() === 0) {
				if (!this.interleaveHoliday) {
					//this.alertmessages(`Can not Apply for the Leave as it is Sunday ${currentDate.toISOString().split('T')[0]} is Sunday`, "danger");
					return true;
				}
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}
		// else{
		//     currentDate.setDate(currentDate.getDate() + 1);
		// }


		// console.log(this.isAnnualHoliday(currentDate))

	}

	// getLeaveForPastDate() {
	// 	this.configKey = 'leaveForPastDate';
	// 	// For now admincofig don't have any perticular value but late wi need to add 3 types;
	// 	// One: Curent month Start | Code: CM01
	// 	// Two: Last Manth start   | Code: LM01
	// 	// Three: Total 30 Days    | Code: TD30
	// 	// Three: Freeze date from contract client feese date  | FD00
	// 	this.empLeaveService.getLeaveForPastDate(this.configKey).subscribe({
	// 		next: (res) => {
	// 			console.log("Min Range", res)
	// 			this.leaveForPastDate = res.adminConfig;
	// 			var date = new Date();
	// 			// From starting of current month.
	// 			if (this.leaveForPastDate === 'CM01') {
	// 				console.log("Inside : CM01")
	// 				this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	// 			}
	// 			// From starting of last month.
	// 			else if (this.leaveForPastDate === 'LM01') {
	// 				console.log("Inside : LM01")
	// 				this.firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
	// 			}
	// 			// 30 days from current day.
	// 			else if (this.leaveForPastDate === 'TD30') {
	// 				console.log("Inside : TD30")
	// 				this.firstDay = new Date(date.setDate(date.getDate() - 30));
	// 			}
	// 			// From Freeze Date.
	// 			// Pending for Now Coz Freeze only for Contract Client Part Later might be there for complete application.
	// 			else if (this.leaveForPastDate === 'FD00') {
	// 				// this.firstDay = 
	// 				console.log("Freeze date is not applicable for application")
	// 			}
	// 			$('input[name="singledaterange"]').daterangepicker({
	// 				singleDatePicker: true,
	// 				// Set min limit for date range picker 
	// 				minDate: this.firstDay,
	// 			})
	// 			$('input[name="daterange"]').daterangepicker({
	// 				opens: 'right',
	// 				// Set min limit for date range picker 
	// 				minDate: this.firstDay,
	// 			})
	// 		}, error: (err) => {
	// 			console.log(err)
	// 		}
	// 	})
	// }

	getLeaveForPastDate() {
		this.configKey = 'allowedPastDate';
		this.empLeaveService.getLeaveForPastDate(this.configKey).subscribe({
			next: (res) => {
				console.log("Min Range", res)
				this.leaveForPastDate = res.adminConfig;
				this.leaveForPastDate = parseInt(this.leaveForPastDate);
				var date = new Date();
				if (typeof this.leaveForPastDate === 'number') {
					console.log("Inside: Number");
					if (this.leaveForPastDate === 0) {
						this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
					} else {
						this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
						this.firstDay.setDate(this.firstDay.getDate() - this.leaveForPastDate);
					}
				} else if (this.leaveForPastDate === 'FD00') {
					console.log("Freeze date is not applicable for application");
				}

				$('input[name="singledaterange"]').daterangepicker({
					singleDatePicker: true,
					minDate: this.firstDay,
				});

				$('input[name="daterange"]').daterangepicker({
					opens: 'right',
					minDate: this.firstDay,
				});

			}, error: (err) => {
				console.log(err)
			}
		})
	}

	getleavebalanceALeavetype(ltype: string): number {
		let balance = this.empleaveCarryforward.find((item, i) => {
			if (item.leavetype === ltype) {
				return item;
			}
		})
		console.log(balance, "ds")
		return balance ? balance.leavecarried : 0;
	}

	reloadthescreen() {
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate(['/employeeleaveapply']);
		});
	}

	addleftstyle() {
		console.log("multiple datess!!!!!!!!!!")
		let datepickerele = <HTMLElement>document.querySelector('.daterangepicker.opensleft.show-calendar');
		if (!datepickerele) {
			return
		}
		datepickerele.style.left = '280';
		datepickerele.classList.add('showcaltoleft')
	}

	getannualHoildayList(): void {
		this.commonService.getAnnualHolidays().subscribe(data => {
			console.log(data, "annual holiday list")
			this.annualhoildaylist = data.annuallist
		});
	}

	checkInterveneLeave(): any {
		let leavetype = this.empLeaveInfo.leaveType;
		this.empLeaveService.getInterveneLeave(leavetype).subscribe(
			data => {
				this.interleaveHoliday = data.isIntervene
				console.log("intervene holiday: ", this.interleaveHoliday);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}
	getsuspensionlist() {
		this.empLeaveService.getsuspensionlist().subscribe(
			(data) => {
				console.log("Data available :", data);
				this.suspension = data.employeesuspension_intermediate;
				console.log("List of all the branch available :", this.suspension);
			},
			(error) => {
				console.log(error);
			}
		);
	}

}
