import { Component, OnInit, ViewChild } from "@angular/core";
import { ExpenseSubmission } from "../models/ExpenseSubmission";
import { ExpensemanagerService } from "../services/expensemanager.service";
import { ExpenseType } from "../models/expenseType";
import { SessionService } from "../services";
import { NgForm } from "@angular/forms";
declare var $:any
@Component({
  selector: "app-expense-master",
  templateUrl: "./expense-master.component.html",
  styleUrls: ["./expense-master.component.css"],
})

export class ExpenseMasterComponent implements OnInit {
  constructor(private expensemanagerService: ExpensemanagerService, private session: SessionService,private sessionService: SessionService)  {
    this.initialize();
  }

  @ViewChild('expenseform', { static: false }) expense: NgForm;

  ngOnInit(): void {
    console.log(" INITIAL ");
     this.getExpenseType();
    this.getExpenseList();
    this.selectedList=null;
  }

  expenseSubmission: ExpenseSubmission = new ExpenseSubmission();
  expenseList: any[] = [];
  selectedList= new ExpenseType();
  id: number;
  client_name: any;
  date_of_expense: Date;
  expense_type_id: number;
  expenseType: any[] = [];
  amount: number;
  user_id: any;
  timestamp: any;
  status: any;
  fileToUpload: File | null = null;
  fileSavedPath: any;
  filename: any;
  expenseName: any;
  uniqueempid: string = this.session.getempid()
  selectedfile:any;
  approvalamount:number;
  initialize() {
    
  }

  // response / request
  clear() {
    this.expenseList = null;
    this.id = null;
    this.client_name = null;
    this.date_of_expense = null;
    this.expense_type_id = null;
    this.amount = null;
    this.user_id = null;
    this.timestamp = null;
    this.status = null;
    this.expenseName=null;
   this.selectedList = null;
   this.expense.reset();
    const fileInput = document.getElementById('file') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.getExpenseList();
  }

  submit() {
    this.expense.form.markAllAsTouched()

    if (this.expense.invalid) {
      return;
    }

    console.log("Expense Name", this.selectedList);
    console.log("Expense Name", this.selectedList.expenseName);
    this.expenseSubmission.client_name = this.client_name;
    this.expenseSubmission.date_of_expense = this.date_of_expense;
    this.expenseSubmission.expense_type_id = this.selectedList.id;
    this.expenseSubmission.amount = this.amount;
    this.expenseSubmission.user_id = this.user_id;
    this.expenseSubmission.timestamp = this.timestamp;
    this.expenseSubmission.status = "PENDING";
    this.expenseSubmission.fileToUpload = this.fileToUpload;
    this.expenseSubmission.expenseName = this.selectedList.expenseName;
    this.expenseSubmission.uniqueempid = this.uniqueempid;

    if (this.expenseSubmission != undefined && this.expenseSubmission != null) {
      this.expensemanagerService
        .saveExpenseSubmission(this.fileToUpload, this.expenseSubmission)
        .subscribe(
          (res) => {
            console.log("response", res);
            console.log("File Uploading", this.fileToUpload);
            console.log(this.expenseSubmission.client_name);
            this.alertmessages("Successfully Saved ", "success");
            this.getExpenseList();
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
    }
    this.clear();
  }
  
  viewdocument(filename: string, path: string) {
    if (path === undefined) {
      path = filename;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.expensemanagerService.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (filename.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.includes('.jpg') || filename.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.toLowerCase().includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else {
          console.log("Other types are not recommended!");
        }
      },
      (error) => { },
    )

  }

  myDocumentDownload(path: any, filename: any) {
    console.log("path ", path);

    this.fileSavedPath = path;
    this.filename = filename;
    console.log("The File Saved Path is", this.fileSavedPath);
    this.downloadImage();
  }

  downloadImage() {
    console.log("Download Image is called");
    this.expensemanagerService.downloadFile(this.fileSavedPath).subscribe(
      (data) => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.filename;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      (error) => {
        console.log("Error occured in download file");
      }
    );
  }
  
  deleteExpense() {
    const id = this.id;
    console.log("delete expense : ", id, " ");

    this.expensemanagerService.deleteExpenseSub(id).subscribe(
      (data) => {
        console.log("delete  Expense ", data);
        this.alertmessages("Successfully deleted ", "success");
        this.clear();
        this.getExpenseList();
        $('#showAlertVer').modal('toggle');
      },
      (error) => {
        this.alertmessages("Expense is in Use ", "danger");
        $('#showAlertVer').modal('toggle');
        console.log(error);
      }
    );
    this.clear();
  }

  getExpenseList() {
    this.expensemanagerService.getExpenseList(this.sessionService.getloggedusername()).subscribe(
      (data) => {
        console.log("Data available :", data);
        this.expenseList = data.expSubmissions;
        console.log("List of all the Expense available :", this.expenseList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getExpenseType(){
    this.expensemanagerService.getExpenseTypeList().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.expenseType = data.expenseType;
        console.log("List of all the branch available :", this.expenseType);
      },
      (error) => {
        console.log(error);
      }
    );

  }

  saveFun() {
    console.log("Check value", this.client_name);
  }

  onFileSelected(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  onDeleteHandler(id) {
    // console.log("delete : ", info);
    this.id=id;
    $('#showAlertVer').modal('toggle');
  }
  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
