<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">

        <div class="alertcomp">
        </div>
        <!-- <div class="main-panel">
            <div class="content">
                <div class="container-fluid" role="main">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3>Employee Master</h3>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="container">
                                            <form [formGroup]="form" (ngSubmit)="submit()">
                                                <formly-form [model]="model" [fields]="fields" [options]="options"
                                                    [form]="form"></formly-form>
                                                <button type="submit"
                                                    class="btn btn-primary submit-button">Submit</button>
                                                <button type="button" class="btn btn-danger medium"
                                                    (click)="options.resetModel()">Reset</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        

        <!-- <div class="alert alert-success mt-4 d-none" id="uniquealert"  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
        <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  successfully Saved.</div> -->

        <!-- header pop up  -->
        <div class="page-header d-xl-flex d-block">
            <!-- <div class="page-leftheader">
                <div class="page-title">Add Employee</div>
            </div> -->
            <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">

                        <!-- <button class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#faceRecognitionModal">
                            Add Face Recognition</button> -->

                        <button class="btn btn-primary me-3" data-bs-toggle="modal" data-bs-target="#fourModal">
                            Weekly Holidays</button>
                        <button class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#EmployeeUploadModal" (click)="set_emp_upload()">
                            Employee Upload</button>
                        <button class="btn btn-primary me-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Salary Details</button>


                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#additionalModal"> Additional Details</button>

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#secondaryModal" (click)="set_emp_secondary_dep()"> Secondary
                            Department</button>

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#thirdModal">Leave Type</button>

                        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                data-bs-target="#familyModal"> Family Details</button> -->
                    </div>
                </div>
            </div>
            <!-- <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <button class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="E-mail">
                            <i class="feather feather-mail"></i> </button>
                        <button class="btn btn-light" data-bs-placement="top" data-bs-toggle="tooltip" title="Contact">
                            <i class="feather feather-phone-call"></i> </button>
                        <button class="btn btn-primary" data-bs-placement="top" data-bs-toggle="tooltip" title="Info">
                            <i class="feather feather-info"></i> </button>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- main  -->
        <div class="row">
            <div class="col-xl-3 col-md-12 col-lg-12">
                <div class="card box-widget widget-user" style="margin-bottom:10px">
                    <div class="card-body text-center">
                        <div class="widget-user-image mx-auto text-center">
                            <input type="file" id="profile" accept="image/png, image/jpeg"
                                (change)="getProfilePhoto($event)" style="visibility: hidden;">
                            <label for="profile" class=" w-100">
                                <!-- <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                    [src]="url|| './../../../assets/template_assests/images/users/1.jpg'"> -->
                                <ng-container *ngIf="empObj.gender =='M'">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/male.png'">
                                </ng-container>

                                <ng-container *ngIf="empObj.gender =='F'">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/female.png'">
                                </ng-container>

                                <ng-container *ngIf="empObj.gender ==undefined">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/1.jpg'">
                                </ng-container>
                            </label>
                        </div>
                        <div class="pro-user mt-3">
                            <h5 class="pro-user-username text-dark mb-1 fs-16">{{model.name || "Name"}}</h5>
                            <h6 class="pro-user-desc text-muted fs-12">{{empObj.designationName || ""}}</h6>
                        </div>

                    </div>

                    <!-- <div class="card-footer p-0">
                        <div class="row">
                            <div class="col-6 text-center py-5 border-end">
                                <h5 class="fs-12 font-weight-semibold mb-3">January</h5>
                                <h5 class="mb-2">
                                    <span class="fs-18 text-success">0</span>
                                    <span class="my-auto fs-9 font-weight-normal  ms-1 me-1">/</span>
                                    <span class="fs-18 font-weight-semibold text-dark">31</span>
                                </h5>
                                <h5 class="fs-12 mb-0">Attendance</h5>
                            </div>
                            <div class="col-6 py-5 text-center ">
                                <h5 class="fs-12 font-weight-semibold mb-3">Year-2021</h5>
                                <h5 class="mb-2">
                                    <span class="fs-18 text-orange">0</span>
                                    <span class="my-auto fs-9 font-weight-normal  ms-1 me-1">/</span>
                                    <span class="fs-18 font-weight-semibold text-dark">41</span>
                                </h5>
                                <h5 class="fs-12 mb-0">Leaves</h5>
                            </div>

                        </div>
                    </div> -->

                </div>
            </div>

            <div class="col-xl-9 col-md-12 col-lg-12" style="border-radius: 13px ;">
                <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off"
                    class="darkmode_formly lightmode_formly" enctype="multipart/form-data">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>

                    <div class="actionBar pe-4 ps-4" style="padding: 0px; margin-top: -20px;">
                        <div class="clearfix"> </div>
                        <div>

                            <button type="button" class="btn btn-primary m-2" *ngIf="!enablebackBtn"
                                (click)="redirectToSearchEmployee($event)">Search
                                Employee Details </button>
                            <button type="button" class="btn btn-primary m-2" *ngIf="enablebackBtn"
                                (click)="redirectToSearchEmployeeBack($event,2)">Back
                            </button>
                            <button type="submit" class="btn btn-success submit-button m-2"
                                [ngClass]="{'disable-div': disDiv}"
                                [hidden]="empObj?.isedit === 'true' ||  empObj?.isedit === 'false'"
                                id="btndisabled">Save</button>

                            <button type="submit" class="btn btn-secondary submit-button m-2"
                                [ngClass]="{'disable-div': disDiv}"
                                *ngIf="allowAdminToEdit && empObj?.isedit !== 'false' && empObj?.isedit">
                                Update
                            </button>

                            <!-- [hidden]="empObj?.isedit === 'false' || !empObj?.isedit"> -->

                            <!-- {{empObj?.isedit === 'false' || !empObj?.isedit}} -->

                            <button type="button" class="btn btn-danger medium m-2" (click)="resetobj()">Clear</button>

                            <!-- <button, type="button" class="btn btn-info medium " (click)="redirectToSearchEmployee($event)">Back</button> -->

                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Salary Details -->
        <div class="modal  fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center mb-3">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Salary Details As Of
                        </h5>
                        <!-- Current Date Field -->
                        <div class="current-date ms-3">
                            <input id="currentDate" type="date" class="form-control" [(ngModel)]="currentDate" />
                        </div>

                        <!-- Button Field -->
                        <div class="col-md-3 px-0 ms-3" style="margin-right:200px">
                            <button type="button" id="navigatetarget" class="btn btn-primary w-100 p-md-1"
                                (click)="searchSalaryDetails()">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                            </button>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>

                    <div class="modal-body">
                        <div class=" container-fluid mb-6">
                            <div class="alertcompSalaryDetail">
                            </div>

                            <!-- <div>Salary Detail Additional Component</div> -->

                            <h5 class="card-title mt-3 mb-3 "> Salary Details - Addition Component</h5>

                            <div class="mobile">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table1">
                                    <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-6">Salary head</th>
                                            <th class="border-bottom-0 fs-6">Salary Detail</th>
                                            <th class="border-bottom-0 fs-6">From Date</th>
                                            <th class="border-bottom-0 fs-6">To Date</th>
                                            <th class="border-bottom-0 fs-6">Amount</th>
                                            <th class="border-bottom-0 fs-6">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-th="Salary head">
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="SalaryHead"
                                                    name="SalaryHead" [(ngModel)]="insertaddsalarydetails.salaryHeadId"
                                                    (change)="headChangeadd(insertaddsalarydetails.salaryHeadId)">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <ng-container *ngFor="let hd of additionsalaryMaster">
                                                        <option
                                                            *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                            value="{{hd.salaryHeadId}}">
                                                            {{hd.salaryHeadName}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            </td>

                                            <td data-th="Salary Detail">
                                                <!-- {{salaryDetailMaster | json}} -->
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="salaryDetailId"
                                                    [(ngModel)]="insertaddsalarydetails.salarydetailId"
                                                    name="salaryDetailId">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <option *ngFor="let sd of additiondetailMasterSection"
                                                        value="{{sd.salaryDetailId}}">
                                                        {{sd.salaryDetailName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertaddsalarydetails.fromDate" name="fromdate"
                                                    id="fromdate">
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertaddsalarydetails.toDate" name="todate"
                                                    id="todate" disabled="true">
                                            </td>
                                            <td data-th="Amount">
                                                <input type="number" min="0" class="form-control"
                                                    [(ngModel)]="insertaddsalarydetails.salaryComponentAmount">
                                            </td>
                                            <td>
                                                <button type="button"
                                                    (click)="addAdditionSal(insertaddsalarydetails.salaryHeadId,insertaddsalarydetails.salarydetailId,
                                                    insertaddsalarydetails.fromDate,insertaddsalarydetails.toDate,insertaddsalarydetails.salaryComponentAmount)"
                                                    class="btn btn_fit mb-2"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-plus-lg"
                                                            viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let sh of insertaddsalarydetail; index as i">
                                            <ng-container *ngIf="sh.userId!='Del'">
                                                <td>
                                                    {{sh.salaryHeadName}}

                                                </td>
                                                <td>
                                                    {{sh.salaryDetailName}}
                                                </td>
                                                <td>
                                                    {{sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="fromDate{{i+1}}" name="fromDate" 
                                                        [(ngModel)]="sh.fromDate" 
                                                        [value]="sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''" 
                                                        disabled> -->
                                                </td>

                                                <td>
                                                    {{sh.toDate ? (sh.toDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="toDate{{i+1}}"
                                                        name="toDate" [(ngModel)]="sh.toDate"
                                                        [value]="sh.toDate ? (sh.toDate | date:'mediumDate') : ''"
                                                        disabled> -->
                                                </td>

                                                <td>
                                                    <ng-container *ngIf="!sh.toDate; else displayAmount">
                                                        <input type="number" min="0" class="form-control"
                                                            id="salaryComponentAmount{{i+1}}"
                                                            name="salaryComponentAmount"
                                                            [(ngModel)]="sh.salaryComponentAmount">
                                                    </ng-container>
                                                    <ng-template #displayAmount>
                                                        {{sh.salaryComponentAmount}}
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="!sh.toDate" type="button"
                                                        (click)="deleteAdditiobSal(i)" class="btn btn_fit mb-2">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="red" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                            </svg></span>
                                                    </button>
                                                </td>
                                            </ng-container>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class=" container-fluid mb-6">

                            <!-- <div>Salary Detail Additional Component</div> -->
                            <h5 class="card-title mt-3 mb-3 "> Salary Details - Deduction Component</h5>


                            <div class="mobile">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table1">
                                    <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-6">Salary head</th>
                                            <th class="border-bottom-0 fs-6">Salary Detail</th>
                                            <th class="border-bottom-0 fs-6">From Date</th>
                                            <th class="border-bottom-0 fs-6">To Date</th>
                                            <th class="border-bottom-0 fs-6">Amount</th>
                                            <th class="border-bottom-0 fs-6">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-th="Salary head">
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="SalaryHeads"
                                                    name="SalaryHeads"
                                                    [(ngModel)]="insertdeductsalarydetails.salaryHeadId"
                                                    (change)="headChangededuct(insertdeductsalarydetails.salaryHeadId)">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <ng-container *ngFor="let hd of deductionsalaryMaster">
                                                        <option
                                                            *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                            value="{{hd.salaryHeadId}}">
                                                            {{hd.salaryHeadName}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            </td>

                                            <td data-th="Salary Detail">
                                                <!-- {{salaryDetailMaster | json}} -->
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="salaryDetailIds"
                                                    [(ngModel)]="insertdeductsalarydetails.salarydetailId"
                                                    name="salaryDetailIds">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <option *ngFor="let sd of deductiondetailMasterSection"
                                                        value="{{sd.salaryDetailId}}">
                                                        {{sd.salaryDetailName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertdeductsalarydetails.fromDate" name="fromdate1"
                                                    id="fromdate1">
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertdeductsalarydetails.toDate" name="todate1"
                                                    id="todate1" disabled="true">
                                            </td>
                                            <td data-th="Amount">
                                                <input type="number" min="0" class="form-control"
                                                    [(ngModel)]="insertdeductsalarydetails.salaryComponentAmount">
                                            </td>
                                            <td>
                                                <button type="button"
                                                    (click)="addDeductSal(insertdeductsalarydetails.salaryHeadId,insertdeductsalarydetails.salarydetailId,
                                                    insertdeductsalarydetails.fromDate,insertdeductsalarydetails.toDate,insertdeductsalarydetails.salaryComponentAmount)"
                                                    class="btn btn_fit mb-2"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-plus-lg"
                                                            viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let sh of insertdeductsalarydetail; index as i">
                                            <ng-container *ngIf="sh.userId!='Del'">
                                                <td>
                                                    {{sh.salaryHeadName}}

                                                </td>
                                                <td>
                                                    {{sh.salaryDetailName}}
                                                </td>
                                                <td>
                                                    {{sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="fromDate{{i+1}}" name="fromDate" 
                                                        [(ngModel)]="sh.fromDate" 
                                                        [value]="sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''" 
                                                        disabled> -->
                                                </td>

                                                <td>
                                                    {{sh.toDate ? (sh.toDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="toDate{{i+1}}"
                                                        name="toDate" [(ngModel)]="sh.toDate"
                                                        [value]="sh.toDate ? (sh.toDate | date:'mediumDate') : ''"
                                                        disabled> -->
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="!sh.toDate; else displayAmount">
                                                        <input type="number" min="0" class="form-control"
                                                            id="salaryComponentAmount{{i+1}}"
                                                            name="salaryComponentAmount"
                                                            [(ngModel)]="sh.salaryComponentAmount">
                                                    </ng-container>
                                                    <ng-template #displayAmount>
                                                        {{sh.salaryComponentAmount}}
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="!sh.toDate" type="button"
                                                        (click)="deleteDeductSal(i)" class="btn btn_fit mb-2">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="red" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                            </svg></span>
                                                    </button>
                                                </td>
                                            </ng-container>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <form>
                            <div class="mb-3">
                                <label for="recipient-name" class="col-form-label">Recipient:</label>
                                <input type="text" class="form-control" id="recipient-name">
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Message:</label>
                                <textarea class="form-control" id="message-text"></textarea>
                            </div>
                        </form> -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Employee Upload -->
        <div class="modal fade" id="EmployeeUploadModal" tabindex="-1" aria-labelledby="additionalModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Employee Upload
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-4">
                            <div class="alertcompExcelUpload">
                            </div>

                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <div class="col-xs-12 mobile">
                                    <div message="pattern"><span class=" textColors">
                                            Upload excel with uploaded document details.
                                        </span>
                                        <button style="float: right;" class="btn btn-primary"
                                            (click)="employeeMasterCSVDownload()">Download Template </button>
                                    </div>

                                    <div>
                                        <label for="form-label" class="form-label">Select File</label>
                                        <input type="file" id="file" class="form-control" name="myFilename"
                                            style="box-sizing: border-box;" [value]="excelDoc.file" required="required"
                                            enctype="multipart/form-data" (change)="handleFileInput($event)" />
                                        <span class="help-text text-danger" *ngIf="(!isFileValid)">Upload only '.xls'
                                            file! </span>
                                    </div>
                                    <button type="button" class="btn btn-success w-100 w-md-20 mt-2"
                                        [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" (click)="uploadImage()"
                                        (click)="uploadFile()">Upload
                                        File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>

        <!-- Error Details for excel -->
        <div class="modal fade" id="excelErrors" tabindex="-1" aria-labelledby="excelErrors" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="excelErrors" style="font-size: 20px;">Error Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <!-- <h5 class="card-title mt-3 mb-3">Error Details</h5> -->
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let error of excelErrors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="clear()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- doc upload  -->
        <!-- <div class="row mt-4" >
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="col-xs-12 mobile">
                            <div message="pattern"><span class=" textColors">
                                    Step 2: Upload excel with uploaded document details.
                                    The columns should be as follows. empId, branchId, docGroup, docType, expiryDate,
                                    filePath
                                </span>
                            </div>

                            <div>
                                <label for="form-label" class="form-label">Select File</label>
                                <input type="file" id="file" class="form-control" name="myFilename"
                                    style="box-sizing: border-box;" [value]="excelDoc.file" required="required"
                                    enctype="multipart/form-data" (change)="handleFileInput($event)" />

                            </div>
                            <button type="button" class="btn btn-success w-100 w-md-20 mt-2" (click)="uploadImage()"
                                (click)="uploadFile()">Upload
                                File</button>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Family Information -->
        <!-- <div class="modal  fade" id="familyModal" tabindex="-1" aria-labelledby="familyModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Family Information</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid mb-6">

                            <div class="mobile">

                                <div class="acc-card">
                                    <div class="acc-header" id="headingOne" role="tab">
                                        <h5 class="mb-0">
                                            <a aria-controls="collapseOne" aria-expanded="true"
                                                data-bs-toggle="collapse" href="#collapseOne">Member one</a>
                                        </h5>
                                    </div>
                                    <div aria-labelledby="headingOne" class="collapse" data-parent="#accordion"
                                        id="collapseOne" role="tabpanel">
                                        <div class="acc-body">
                                            <div class="card-body row px-0">
                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating m-0">
                                                        <label class="form-label">Name</label>
                                                        <div>
                                                            <input type="text" class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" name="allocation_percentage" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating m-0">
                                                        <label class="form-label">Date of Birth</label>
                                                        <div>
                                                            <input type="date" class="form-control col-md-12 col-xs-12"
                                                                autocomplete="off" placeholder="Enter End Date"
                                                                autofocus="true" name="end_date" />


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="card-body row px-0">
                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating m-0">
                                                        <label class="form-label">Dependent</label>
                                                        <div>

                                                            <select class="form-control"
                                                                data-style="btn btn-drpdwn btn-round" id="dGroup"
                                                                name="rpt_mang_emp_id">

                                                                <option [ngValue]="undefined" [disabled]="true"
                                                                    [hidden]="true">
                                                                    Select Yes/No</option>
                                                                <option [ngValue]="data"
                                                                    *ngFor="let data of dependentOn">
                                                                    {{data}}
                                                                </option>

                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                                    <div class="form-group label-floating m-0">
                                                        <label class="form-label">Relationship with Employee</label>
                                                        <div>

                                                            <select class="form-control"
                                                                data-style="btn btn-drpdwn btn-round" id="dGroup"
                                                                name="rpt_mang_emp_id">

                                                                <option [ngValue]="undefined" [disabled]="true"
                                                                    [hidden]="true">
                                                                    Select Relationship</option>
                                                                <option [ngValue]="data"
                                                                    *ngFor="let data of relationshipWithEmp">
                                                                    {{data}}
                                                                </option>

                                                            </select>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                

                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Additonal Details -->
        <div class="modal fade" id="additionalModal" tabindex="-1" aria-labelledby="additionalModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Additonal Details
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">

                            <!-- <div>Salary Detail Additional Component</div> -->

                            <h5 class="card-title mt-3 mb-3 "> Additional Details </h5>
                            <div class="mobile">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom">
                                    <thead>
                                        <tr>
                                            <th>Attribute Name</th>
                                            <th class="ps-3">Attribute Value</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of model.employeeattributevalueinfo">
                                            <td data-th="Attribute Name">{{item?.attributename }}</td>
                                            <td data-th="Attribute Value"><input type="text" class="form-control "
                                                    (input)="readamount($event,item , false)"
                                                    placeholder="Enter Attribute value" [value]="item?.attributevalue">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Secondary Department -->
        <div class="modal fade" id="secondaryModal" tabindex="-1" aria-labelledby="secondaryModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;">Select Secondary
                            Department</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">

                            <!-- {{secondaryDepartmentObj | json }} -->
                            <!-- <div>Salary Detail Additional Component</div> -->
                            <!-- <h5 class="card-title mt-3 mb-3 "> Additional Details </h5> -->

                            <ul class="list-group">
                                <li class="list-group-item " style="cursor: pointer;"
                                    *ngFor="let sec of secondaryDepartmentObjFiltered">
                                    <input type="checkbox"
                                        (change)="addSecdepartment($event , sec);$event.stopPropagation()"
                                        class="deCheckall" id="{{sec.name}}">
                                    <label for="{{sec.name}}" style="margin-left: 10px;">{{sec.name}}</label>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="clear()">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Leave Type -->
        <div class="modal fade" id="thirdModal" tabindex="-1" aria-labelledby="thirdModalLabel" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="thirdModalLabel" style="font-size: 20px;">Leave Type</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid ">

                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th>Leave Type</th>
                                        <th>Days</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <!-- <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                        <ng-container *ngIf="uidChecker === false">
                                            <td data-th="Leave Type">{{ leavelist.leaveName }}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="form-control col-md-12 col-xs-12 leaveType"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : LeaveTypeListValue[i].noOfDays }}"
                                                    (change)="onInputChange($event, i)">
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="uidChecker === true">
                                            <td data-th="Leave Type">{{ leavelist.leaveName }}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text" class="form-control col-md-12 col-xs-12 
                                                    leaveType"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : 'Na' }}"
                                                    (change)="onInputChange($event, i)" disabled>
                                            </td>
                                        </ng-container>
                                    </tr> -->


                                    <ng-container *ngIf="uidChecker === true ">
                                        <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                            <td data-th="Leave Type">{{leavelist.info.leaveName}}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="leaveType form-control col-md-12 col-xs-12"
                                                    value="{{ LeaveTypeListValue[i]?.info.noOfDays === -1 ? 'Na' : 'Na' }}"
                                                    (change)="onInputChange($event, i)" disabled>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngIf="uidChecker === false">
                                        <tr *ngFor="let leavelist of LeaveTypeListValue; let i = index">
                                            <td data-th="Leave Type">{{leavelist.leaveName}}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="leaveType form-control col-md-12 col-xs-12"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : LeaveTypeListValue[i].noOfDays }}"
                                                    (change)="onInputChange($event, i)"
                                                    [disabled]="empObj.isedit==='false'">
                                            </td>
                                        </tr>
                                    </ng-container>




                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button *ngIf="uidChecker === false" [hidden]="empObj?.isedit === 'false' " type="button"
                            [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" class="btn btn-primary"
                            data-bs-dismiss="modal" (click)="update_LeaveType()">Update</button>
                        <button *ngIf="uidChecker === false && empObj?.isedit === 'false'" type="button"
                            class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>

                </div>
            </div>
        </div>

        <!-- Face Recognition -->
        <div class="modal fade" id="faceRecognitionModal" tabindex="-1" aria-labelledby="faceRecognitionModal"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Add Face Recognition
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">
                            <div class="mobile">
                                <h3 class="modal-title" id="exampleModalLabel" style="font-size: 10px;color: red;">
                                    Please capture minimum 3 pic for registration
                                </h3>
                                <button (click)="uploadImageFace()">Take a Photo</button>
                                <br>
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">

                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">

                                            <!-- <th class="border-bottom-0">Date</th> -->
                                            <!-- <th class="border-bottom-0">Status</th> -->

                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">ImageName</th>

                                            <!-- <th class="border-bottom-0">Date</th> -->

                                            <th class="border-bottom-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let image of faceregisterdata;let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{image.imagePath}} </td>
                                            <td><a class="btn btn-danger btn-icon btn-sm me-2"
                                                    data-effect="effect-scale"
                                                    (click)="viewprofilepic(image.imagePath,image.imageName)"
                                                    data-bs-toggle="modal" href="#modaldemo8">
                                                    <i class="si si-eye" data-bs-toggle="tooltip"
                                                        data-original-title="view"></i>
                                                </a>

                                                <button class="btn btn-danger btn-icon btn-sm me-2"
                                                    data-bs-toggle="tooltip" (click)="deleteprofilepic(image)"
                                                    data-original-title="Delete">
                                                    <i class="feather feather-trash-2" data-bs-toggle="tooltip"
                                                        data-original-title="delete"></i>

                                                </button>
                                            </td>



                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="saveImage()">Save</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- View Profile -->
        <div class="modal fade" id="modaldemo8" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm modal-dialog-centered text-center" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Profile</h6><button aria-label="Close" class="btn-close"
                            data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                            [src]="url2 || './../../../assets/template_assests/images/users/1.jpg'">
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" data-bs-dismiss="modal" data-bs-toggle="modal"
                            href="#faceRecognitionModal">Close</button>

                    </div>
                </div>
            </div>
            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
        </div>

        <!-- week off -->
        <div class="modal fade" id="fourModal" tabindex="-1" aria-labelledby="fourModalLabel" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog modal-dialog-scrollable">
                <!-- modal-lg -->
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fourModalLabel" style="font-size: 20px;">Weekly Off</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid ">

                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th></th>
                                        <th>Weekday</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="uidChecker === true ">
                                        <tr *ngFor="let off of WeeklyOffList;let i = index">
                                            <td>
                                                <input type="checkbox" (change)="toggleSelection(off)">
                                            </td>
                                            <td>{{ getDayName(off.weeklyOffDay) }}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="uidChecker === false ">
                                        <tr *ngFor="let off of WeeklyOffList;let i = index">
                                            <td>
                                                <input type="checkbox" [checked]="off.weeklyOffId!==-1"
                                                    (change)="toggleSelection(off)"
                                                    [disabled]="empObj.isedit==='false'">
                                            </td>
                                            <td>{{ getDayName(off.weeklyOffDay) }}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        <button *ngIf="uidChecker === false" [hidden]="empObj?.isedit === 'false'" type="button"
                            [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" class="btn btn-primary"
                            data-bs-dismiss="modal" (click)="updateWeeklyType()">Update</button>
                        <button *ngIf="uidChecker === false && empObj?.isedit === 'false'" type="button"
                            class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>

                </div>
            </div>
        </div>

    </div>