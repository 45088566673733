<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp" id="alertcomp"></div>
    <!-- <div class="card-body px-0">
      <div class="page-leftheader my-3">
        <div class="page-title">Apply Salary Loan</div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="salaryadvanceform" class="form-horizontal form-label-left row" #salaryadvanceform="ngForm">
              <div class="row col-md-12 col-sm-12 col-xs-12">

                <div class="col-md-3 col-sm-4 col-xs-4 px-5">
                  <div class="form-group label-floating">
                    <label class="form-label">Amount</label>
                    <div>
                      <input type="number" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        [disabled]="isOnlyView" (change)="onfieldChange()" placeholder="Enter amount"
                        name="enteredamount1" min="0" [(ngModel)]="totalAmount" required #enteredamount="ngModel"
                        [ngClass]="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted) || (enteredamount.valid && totalAmount > maxAmtForLoan) ? 'is-invalid' : ''" />

                    </div>
                    <span class="help-text text-danger"
                      *ngIf="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted) ">Please
                      Enter
                      Amount</span>
                    <span class="help-text text-danger" *ngIf=" enteredamount.valid && totalAmount > maxAmtForLoan">The
                      amount exceeds the limit</span>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Number of Installment(s)</label>
                    <div>
                      <input type="number" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        [disabled]="isOnlyView" (change)="onfieldChange()" placeholder="Enter Number of Installment"
                        name="enterednoOfInstall" [(ngModel)]="noOfInstallment" required #enterednoOfInstall="ngModel"
                        min="0"
                        [ngClass]="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger"
                      *ngIf="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted)">Please
                      Enter
                      Number of Installment</span>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Payment Required on </label>
                    <div>
                      <input type="date" class="form-control" name="date_of_loan" [(ngModel)]="date_of_loan" required
                        [disabled]="isOnlyView" #dateofloan="ngModel"
                        (change)="setInsStartDate(); onDateChange(); onfieldChange()"
                        [ngClass]="(!dateofloan.valid && dateofloan.touched) || (!dateofloan.valid && submitted) || DOLoanErr ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger"
                      *ngIf="(!dateofloan.valid && dateofloan.touched) || (!dateofloan.valid && submitted) || DOLoanErr">Please
                      select valid Date</span>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label"> Installment Deduction From </label>
                    <div>
                      <input type="date" class="form-control" name="Ins_StartDate" [(ngModel)]="Ins_StartDate" required
                        [disabled]="isOnlyView" #InsStartDate="ngModel" (change)="onfieldChange(); onDateChange()"
                        [ngClass]="(!InsStartDate.valid && InsStartDate.touched) || (!InsStartDate.valid && submitted) || ISDErr ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger"
                      *ngIf="(!InsStartDate.valid && InsStartDate.touched) || (!InsStartDate.valid && submitted) || ISDErr">Please
                      select valid Date</span>
                    <span class="help-text text-danger" *ngIf=" InsStartDate.valid && dateError">The installment start
                      date must be later than the loan date.</span>
                  </div>
                </div>

                <div class="col-md-3 col-sm-4 col-xs-4 px-5">
                  <div class="form-group label-floating">
                    <label class="form-label">Reason</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        [disabled]="isOnlyView" placeholder="Enter reason" name="reasonForApply" [(ngModel)]="reason"
                        #reasonForApply="ngModel" required
                        [ngClass]="(!reasonForApply.valid && reasonForApply.touched) || (!reasonForApply.valid && submitted) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger"
                      *ngIf="(!reasonForApply.valid && reasonForApply.touched) || (!reasonForApply.valid && submitted)">Please
                      Enter Reason</span>
                  </div>
                </div>

                <div *ngIf="showBasedOnApproverScreen  && !isEdit " class="col-md-3 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Apply For</label>
                    <div>
                      <div>
                        <select class="form-control" type="text" style="width: 100%;" (change)="onChange($event)">
                          <option [selected]="selectedType === 'select'" value="ME">ME</option>
                          <option value="Team_Member">Team
                            Member</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-md-4 col-sm-4 col-xs-6" *ngIf="selectedType === 'Team_Member' ">
                  <div class="form-group label-floating">
                    <label class="form-label">Select Team Member</label>
                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                      placeholder="Enter Employee Name / ID " [(ngModel)]="query" autocomplete="off"
                      (input)="getEmployeeByNameOrId()" list="employeeMasterLucene" autofocus="autofocus"
                      (ngModelChange)="onChange1($event)">
                    <datalist id="employeeMasterLucene">
                      <option value="{{emp.employeeid}}" data-value={{emp.name}}
                        *ngFor="let emp of employeeMasterLucene">
                        {{emp.employeeid}}
                      </option>
                    </datalist>
                  </div>
                </div>



                <div class="actionBar" class="col-md-6 col-sm-12 col-xs-12 px-5" style="margin-top:26px">
                  <!-- <div class="container p8"> -->
                  <!-- Left side button -->
                  <!-- <button class="btn btn-primary" type="button" style="float: right;"
                    (click)="!isEdit ? calcInstallment(): editcalcInstallment() " data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                    Varify Installments
                  </button> -->

                  <!-- <button *ngIf="isEdit" class="btn btn-primary" type="button" style="float: left;"
                    (click)="editcalcInstallment()" data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                    Varify Installments
                  </button> -->

                  <!-- Right side buttons -->
                  <!-- <div style="float: right;"> -->

                  <button class="btn btn-primary" type="button" style="margin-right: 8px;"
                    (click)="!isEdit ? calcInstallment(): editcalcInstallment() " data-bs-toggle="modal"
                    data-bs-target="#calInstallmentModel">
                    Verify Installments
                  </button>
                  <button *ngIf="!isEdit && !isOnlyView" class="btn btn-success" style="margin-right:0.5rem"
                    type="button" (click)="submit()">
                    Submit
                  </button>
                  <button *ngIf="isEdit && !isOnlyView" class="btn btn-success" style="margin-right:0.5rem"
                    type="button" (click)="update()">
                    Update
                  </button>
                  <button *ngIf="isEdit && !isOnlyView" class="btn btn-danger " style="margin-right:0.5rem"
                    type="button" (click)="clear()">
                    Reset
                  </button>
                  <button *ngIf="!isEdit && !isOnlyView" class="btn btn-danger" type="button" (click)="clear()">
                    Clear
                  </button>
                  <button *ngIf="isOnlyView" class="btn btn-primary ms-2" type="button" (click)="clear()">
                    Back
                  </button>

                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row top_margin">
      <div class="col-md-12">
        <div class="card">

          <div class="card-body">
            <!-- <form> -->
            <div class="table-responsive">
              <div class="scrollsearchresults" id="scrolllength">
                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                  <thead class="tablerow stickyatTop">
                    <tr class="rowcolors stickyatTop">
                      <!-- <th class="border-bottom-0 fs-7">Advance ID</th> -->
                      <th class="border-bottom-0 fs-7">Loan Applied For</th>
                      <th class="border-bottom-0 fs-7">Loan Disbursement Date</th>
                      <th class="border-bottom-0 fs-7">Amount</th>
                      <th class="border-bottom-0 fs-7">Status</th>
                      <th class="border-bottom-0 fs-7">Reason</th>
                      <th class="border-bottom-0 fs-7">Action</th>
                    </tr>
                  </thead>
                  <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                    <tr *ngFor="let sal of salaryAdvanceList; let i = index">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>

                        {{sal.employeeName}}
                      </td>
                      <td>{{ sal.dateOfLoan | date: 'mediumDate'}}</td>
                      <td style="text-align: right;">{{ sal.totalAmount | currency : "INR" : "symbol" : "1.2-2"}}</td>
                      <td>
                        <span *ngIf="sal.status === 'PENDING'" class="w-75 badge badge-warning">{{sal.status}}</span>
                        <span *ngIf="sal.status === 'APPROVED'" class="w-75 badge badge-success">{{sal.status}}</span>
                        <span *ngIf="sal.status === 'REJECTED'" class="w-75 badge badge-danger">{{sal.status}}</span>
                        <span *ngIf="sal.status === 'PAID'" class="w-75 badge badge-success">{{sal.status}}</span>
                      </td>

                      <td>{{ sal.reason }}</td>

                      <td>
                        <button *ngIf="sal.status === 'PENDING'" class="btn btn-primary btn-icon btn-sm me-2"
                          data-method="edit" title="Edit" (click)="editSalaryAdvance(sal)">
                          <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                        </button>
                        <button *ngIf="sal.status === 'APPROVED' || sal.status === 'PAID'" class="btn btn-primary btn-icon btn-sm me-2"
                          data-method="view" title="View" (click)="editSalaryAdvance(sal);viewSalaryAdvance()">
                          <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="edit"></i>
                        </button>

                        <!-- <button class="btn btn-primary btn-icon btn-sm me-2"
                       style="width: 32px;">
                      <i class="fa fa-eye" ></i>
                    </button> -->
                        <!-- <button *ngIf="sal.status === 'PENDING'" class="btn btn-danger btn-icon btn-sm me-2"
                          (click)="delete(sal)">
                          <i class="fa fa-trash"></i>
                        </button> -->
                        <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete" title="Delete"
                          (click)="onDeleteHandler(sal)" data-bs-target="#showAlertWarning">
                          <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="delete"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal  fade" id="calInstallmentModel" tabindex="-1" aria-labelledby="calInstallmentModel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="calInstallmentModellLabel" style="font-size: 20px;">Installment Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <div class="alertmodel" id="alertmodel"></div>


          <div class=" container-fluid ">

            <!-- <div>Salary Detail Additional Component</div> -->

            <!-- <h5 class="card-title mb-3 "> Salary Detail Additional Component </h5> -->

            <div class="mobile">
              <div style="padding-bottom: 10px;">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <strong>Loan Amount: </strong>
                    <span>{{ totalAmount | currency : "INR" : "symbol" : "1.2-2"}}</span>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <strong>Total Amount Payable: </strong>
                    <span>{{ totalAmountToPay | currency : "INR" : "symbol" : "1.2-2"}}</span>
                    <strong> (including Interest)</strong>
                  </div>
                </div>
              </div>
              <div class="scrollsearchresults" id="scrolllength" style="max-height: 57vh !important">
                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table1">
                  <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                  <thead class="tablerow stickyatTop">
                    <tr class="rowcolors">
                      <th class="border-bottom-0 text-center fs-6 ">Month</th>
                      <th class="border-bottom-0  fs-6 ">
                        <span class="installment-text">Installment Amount</span>
                        <span class="interest-text">(with {{interestRate}}% Interest)</span>
                      </th>
                      <th class="border-bottom-0 text-center fs-6 ps-3">Additional Amount Recovered
                        <i class="fa fa-info-circle" data-bs-toggle="tooltip-primary"
                          title="*This is amount recovered from the employee in addition to the installment amount"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of installmentComp ; let i = index">
                      <td data-th="Date">{{item.instDate| date: 'mediumDate'}}</td>
                      <td data-th="Installment Amount">
                        <input type="number" min="0" size="10" class="form-control"
                          (input)="changeAmount($event,item , i)" [value]="item?.instAmount"
                          [(ngModel)]="item.instAmount" placeholder="Enter amount"
                          [disabled]="isOnlyView || item.isSalaryGenerated"
                          (change)="updateInstallmentCompBasedOnInstallmentAmount(i,item.instAmount);">
                      </td>
                      <td data-th="Paid Amount">
                        <!-- <input type="number" min="0" class="form-control "
                                                (input)="readamount($event,item , true)" placeholder="Enter amount"
                                                data-name="{{item?.salaryDetailName}}"
                                                [value]="item?.salaryComponentAmount === -1 ? 0.0 : item?.salaryComponentAmount"> -->
                        <input type="number" min="0" class="form-control " [value]="item?.paidAmount "
                          placeholder="Enter amount" disabled>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>



        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Reset</button> -->
          <button *ngIf="!isOnlyView" type="button" class="btn btn-primary" (click)="modelOk()">Save</button>
        </div>
      </div>
    </div>
  </div>


</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
  <div class="modal-dialog">
    <div class="modal-content animate">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
        <h4 class="modal-title">Warning</h4>
      </div>

      <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
        <!-- auto scroll -->
        <span><b>Apply Salary Loan data will be deleted permanently. Are you sure you want to continue?</b></span>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger medium" (click)="delete()">OK</button>
        <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
      </div>

    </div>
  </div>
</div>