import { Component, Input, OnInit } from '@angular/core';
import { EventCalendar } from 'src/app/models/EventCalendar';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Menu } from 'src/app/services/menu.Service';


@Component({
  selector: 'app-dashboard-event-calendar',
  templateUrl: './dashboard-event-calendar.component.html',
  styleUrls: ['./dashboard-event-calendar.component.css']
})
export class DashboardEventCalendarComponent implements OnInit {
  @Input() eventDayList:EventCalendar[] = [];
  constructor(private dashboardService: DashboardService, private menuService: Menu) { }

  ngOnInit(): void {
  }
  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }
  navigateEventCalendar() {
    localStorage.setItem('navigateDashboard', 'true')
  }
}


