import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { SalaryDetailMaster } from 'src/app/models/SalaryMaster';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';
import { EmployeeAttendenceProxyService } from 'src/app/services/employee-attendence-proxy.service';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';
import { reviewpayslipService } from 'src/app/services/reviewpayslips.service';
declare var $: any;

@Component({
  selector: 'app-generate-salary',
  templateUrl: './generate-salary.component.html',
  styleUrls: ['./generate-salary.component.css']
})
export class GenerateSalaryComponent implements OnInit {
  fromDate: any;
  toDate: any;
  query: any;
   employeeMaster: EmployeeMaster[];
   selectedempid: any="all";

  constructor(private employeeService: EmployeeAttendenceProxyService, private reviewPay: reviewpayslipService,private empLeaveService: EmployeeLeaveService,
    private empsalaryservice: EmpsalaryslipService, private cdr: ChangeDetectorRef, private documentInfoService: DocumentInfoService
  ) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }

  addOrUpdate: any;
  branchInfo: any;
  activeEmployeeList: any;
  startDate: boolean;
  submitted: boolean;
  employeeid: any = 'all';
  branch: any = 'all';
  voidSalaryreason: string = '';
  fetchSalaryData: any;
  endDate: boolean; // Flag to show/hide the error message
  salaryDetail = new SalaryDetailMaster();
  generate: boolean = false; // by default it false 

  ngOnInit(): void {
    this.getAllBranches();
    this.getAllActiveEmployee();
    this.getSalaryDetail();
  }

  getSalaryDetail() {
    this.empsalaryservice.getSalaryDetailsForGenerateSalary().subscribe(
      (data) => {
        this.salaryDetail = data.salaryDetailMaster;
      }, (error) => {
        console.log(error)
      }
    )
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
        console.log("error in branch ", error);
      }
    );
  }

  getAllActiveEmployee() {
    console.log("Called all active employee >> >> >>")
    this.employeeService.getAllActiveEmployeeList().subscribe(
      data => {
        this.activeEmployeeList = data.employeeMasterData;
        console.log("activeEmployeeList ", this.activeEmployeeList);
      },
      error => {
        console.log("error in active employee ", error);

      }
    );
  }

  onGenerateChange(info) {
    this.fetchSalaryData = [];
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    console.log("this.fromDate", this.fromDate);
    console.log("this.toDate ", this.toDate);
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  // generate salary slip 
  generateSalarySlip() {

    const obj = {
      //empId: this.employeeid,
      empId: this.selectedempid,
      salaryDate: this.fromDate,
      salaryToDate: this.toDate,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
      generate: this.generate
    }
  
    if (obj.salaryDate == undefined || obj.salaryToDate == undefined) {
      this.alertmessages("Please select month to generate salary", "danger");
      return;
    }
    console.log("-------empId--------- ", obj.empId);

    console.log("generate Salary function ", obj);
    this.reviewPay.generatePayslip(obj).subscribe(
      data => {
        if (data.successMessages[0].key == 'EXIST') {
          this.generate = true;
          this.alertmessages("Salary Already Generated For Selected Month", "danger");
          this.fetchSalaryData = [];
          this.penaltyPopup();
        } else {
          this.alertmessages("Salary Generation Successfully", "success");
          this.fetchSalary();
        }
      }, error => {
        this.generate = false;
        console.log("error occured in generate Salary", error);
      }
    )
  }

  penaltyPopup() {
    $("#plusModal1").modal("toggle");
    // this.cdr.detectChanges();
  }

  saveData() {
    if (!this.voidSalaryreason || this.voidSalaryreason.trim() === '') {
      console.log('Remarks cannot be empty.', this.voidSalaryreason);
    } else {
      console.log('Data saved successfully.', this.voidSalaryreason);
      // this.generate = false; // Hide modal
      $("#plusModal1").modal("toggle");
      this.generateSalarySlip();
    }
  }

  closemodal() {
    this.generate = false; // Hide modal
    $("#plusModal1").modal("toggle");
    this.voidSalaryreason = '';
    console.log(this.voidSalaryreason);
  }

  fetchSalary() {
    this.fetchSalaryData = [];
    this.voidSalaryreason = '';
    this.generate = false; // is set false has it has fetch generate salary 

    const obj = {
      // empId: this.employeeid,
      empId: this.selectedempid,
      salaryDate: this.fromDate,
      salaryToDate: this.toDate,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
      generate: this.generate
    }

    if (obj.salaryDate == undefined || obj.salaryToDate == undefined) {
      this.alertmessages("Please select month to generate salary", "danger");
      return;
    }

    console.log("fetch Salary function ");
    this.reviewPay.fetchPaySlip(obj).subscribe(
      data => {
        this.fetchSalaryData = data.fetchSalaryDetails;
        console.log(this.fetchSalaryData);

      }, error => {
        this.alertmessages("Please Try again something went wrong", "Danger");
      }
    )
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  salaryReportCSVDownload() {

    this.generate = false;

    const obj = {
      // empId: this.employeeid,
      empId: this.selectedempid,
      salaryDate: this.fromDate,
      salaryToDate: this.toDate,
      branchId: this.branch,
      void_reason: this.voidSalaryreason,
      generate: this.generate
    }
    console.log("salary report for selected month ", obj);
    this.documentInfoService.downloadSalaryReportCSVFile(obj).subscribe(
      data => {

        console.log(data)
        var file = new Blob([data], { type: 'application/vnd.ms-excel' });
        var fileURL = URL.createObjectURL(file);
        var anchor = document.createElement("a");
        anchor.download = "SalaryReport.xls";
        anchor.href = fileURL;
        anchor.click();
      },
      error => {
        console.log("Error Occured ", error);
      })
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query)
    this.query = this.query.trim();
    console.log("this.selectedempid",this.query);
    
    if (!this.query) {
      console.log("old",this.selectedempid);
      this.selectedempid = "all";
      console.log("new",this.selectedempid);
  }
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }
    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMaster = data.employeeMasterData;
          console.log("Len ", this.employeeMaster);
          this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
          console.log("Emp Data ", this.employeeMaster[0].uniqueemployeeid);
        },
        error => {
          console.log("Error ", error);
 
        }
      )
    }
  }

  
}
