import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Employeesuspensioninfo } from '../models/Employeesuspensioninfo';

@Component({
  selector: 'app-employee-suspension-report',
  templateUrl: './employee-suspension-report.component.html',
  styleUrls: ['./employee-suspension-report.component.css']
})
export class EmployeeSuspensionReportComponent implements OnInit {
  statusValue: string = 'All';
  query: any;
  queryName: any;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  department: any[] = [];
  limitPara: any;
	offsetPara: any;
  selecteddepartment: string= "0";
  fromDate: string;
	toDate: string;
  leaveStatus: string;
  suspenseReport: Employeesuspensioninfo[]=[];
  client_id: EmployeeMaster[];
  keyinempid: any | undefined;
  employeeMaster: EmployeeMaster[];
  selectedempid: any;

  constructor(private empLeaveService: EmployeeLeaveService,private applicationSettingsService: ApplicationSettingsService,
    private employeeMasterservice:EmployeeMasterservice) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
   }

  ngOnInit(): void {
    this.getDepartment();
    this.limitPara = 13;

		this.offsetPara = 0;
  
  }
  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the department available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSuspensionStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}
  getEmployeeByNameOrId() {
    console.log("query", this.query)
    this.query = this.query.trim();
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
      this.employeeMaster = []
    }

    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMaster = data.employeeMasterData;
          this.selectedempid = this.employeeMaster[0].uniqueemployeeid;
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

    onScroll() {
      this.offsetPara = this.offsetPara + 13;
      console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
      this.searchSuspensionReport();
  
    }
  
    limitpara() {
      this.offsetPara = 0;
      console.log(" on clikc limit para ", this.limitPara)
      console.log("this offset", this.offsetPara)
    }
  
    @HostListener('scroll', ['$event'])
    scrolled() {
  
      console.log("Scrolled >>> ")
      this.onScroll();
    }
  
  
  searchSuspensionReport() {
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    //   this.statusValue ="All";
    //  this.selecteddepartment = "0";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.client_id[0].uniqueemployeeid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }
    this.employeeMasterservice.getEmployeeSuspensionReport(this.fromDate, this.toDate,this.statusValue,this.selecteddepartment, id,employeename, this.limitPara, this.offsetPara).subscribe(
        (data) => {
          this.suspenseReport=data.employeesuspension_intermediate;
          console.log("Data available :", data);
          console.log(data.employeesuspensioninfo);
          console.log(this.suspenseReport);
          
        },
        (error) => {
          console.log(error);
        }
      );
  }
  clear(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
     this.selecteddepartment = "0";
    this.query='';
   }
}
