import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from '../models/employeeMasterts';
import { DepartmentInfo } from '../models/departmentInfo';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { CommonService } from '../services/common.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';

@Component({
  selector: 'app-salary-advance-report',
  templateUrl: './salary-advance-report.component.html',
  styleUrls: ['./salary-advance-report.component.css']
})
export class SalaryAdvanceReportComponent implements OnInit {

  startDate: boolean; 
  endDate: boolean;
  submitted: boolean;
  displayDateField: boolean;
  fromDate: string;
  toDate: string;
  employeename: string;
  employeeid: string;
  queryName: any;  
  query: any;
  employeeMasterLucene: EmployeeMaster[];
  selectedEmpList: EmployeeMaster[];
  departments: DepartmentInfo[];
  department: number = 0;
  limitPara: any; 
  offsetPara: any;
  salaryAdvanceList: any[] = [];
  tempsalaryAdvanceList: any[] = [];
  statusFilter:String = 'PENDING';
  typeFilter:String = 'A';
  dateFromLable : String = 'Applied From';
  dateToLable : String = 'Applied To';

  constructor(private salaryAdvanceService: SalaryAdvanceService,private empLeaveService: EmployeeLeaveService,
    private common: CommonService,) { }

  ngOnInit(): void {
    this.getdepartment();
    this.displayDateField = false;
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
   
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    
  }


  search() {
    this.salaryAdvanceList = [];
    this.getSalaryAdvanceReport();
  }

  getSalaryAdvanceReport(){
    
    this.salaryAdvanceService.getSalaryReport(this.fromDate , this.toDate , this.statusFilter,this.typeFilter ,this.department,this.employeeid).subscribe(
      data => {
        console.log("Saved data : ", data);
        this.salaryAdvanceList = data.salaryAdvanceList;
        this.tempsalaryAdvanceList = data.salaryAdvanceList;
       
        console.log("this.salaryAdvanceList  : ", this.salaryAdvanceList);
        console.log("this.tempsalaryAdvanceList  : ", this.tempsalaryAdvanceList);
      }, error => {

      });
  }

  getdepartment() {
    this.common.getSearchdeparment1().subscribe({
      next: (res) => {
        this.departments = res;
      },
      error: (e) => {
        console.log("error occured while getting all the departments")
      }
    })
  }

  onScroll(){
    
  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    console.log("this.fromDate", this.fromDate);
    console.log("this.toDate ", this.toDate);
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  getEmployeeByNameOrId() {

    const [name, id] = this.employeename.split('/');
    this.queryName = name;
    // this.employeeid = id;
    this.employeename = name;
    console.log("employeename ", this.employeename);
    console.log("Len ", this.query);
    if (this.employeename && this.employeeMasterLucene) {
      this.selectedEmpList = this.employeeMasterLucene.filter((dName) => {
        if(dName.name === this.employeename){
          console.log("dName ",dName);
          return dName;
        }
      });
      if(this.selectedEmpList[0]){
        this.employeeid = this.selectedEmpList[0].uniqueemployeeid.toString();
      }
    
    console.log("employeeid ", this.employeeid);
    }

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = []
    }
    if (this.queryName.length >= 3) {

      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMasterLucene = data.employeeMasterData;

        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  modelDepartment(e) {
    console.log(this.department, e)
    this.limitPara = 10;
    this.offsetPara = 0;
  }

  scrollToBottom() {
    const element = document.getElementById('navigateclient'); // Replace with the actual ID of your search results container
    element.scrollTop = element.scrollHeight;
}

limitpara() {
  this.offsetPara = 0;
}

salaryAdvanceReportDownload() {
  if(!this.salaryAdvanceList || this.salaryAdvanceList.length === 0 ){
    return;
  }
  var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
  // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
  this.salaryAdvanceService.salaryAdvanceReportDownload(this.fromDate , this.toDate , this.statusFilter,this.typeFilter ,this.department,this.employeeid).subscribe(data => {
    // console.log(data)
    // this.uniqueemployeeid = null;
    var file = new Blob([data], { type: 'application/vnd.ms-excel' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "SalaryAdvanceReport.xls";
    anchor.href = fileURL;
    anchor.click();
    //window.open(fileURL);
  },
    error => {
      console.log("Error Occured ", error);
      // console.log("Error Code ",error.status);
    })
}

clear(){
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = currentDate.getMonth() + 1;
  // const day = currentDate.getDate();
  // this.currentDate = `${year}-${month}-${day}`;
  // this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
  // const lastDay = new Date(year, month, 0).getDate();
  // this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  // this.employeename = null;
  // this.uempid=null;
  // this.otreportinfo = null;
  // this.getdepartment();
}

  onStatusChange() {
    this.displayDateField = true;
    this.salaryAdvanceList = [];
    if (this.statusFilter == "PENDING") {
      this.displayDateField = false;
    }else if(this.statusFilter == "APPROVED"){
      this.dateFromLable = 'Approved From';
      this.dateToLable = 'Approved To';
    }else if(this.statusFilter == "REJECTED"){
      this.dateFromLable = 'Rejected From';
      this.dateToLable = 'Rejected To';
    }else if(this.statusFilter == "PAID"){
      this.dateFromLable = 'Disbursed From';
      this.dateToLable = 'Disbursed To';
    }
  }

}
