<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Apply Leave</div>
            </div>
        </div> -->
        <!--End Page header-->



        <!-- Row -->
        <div class="row">
            <div class="col-xl-6 col-md-12 col-lg-12">
                <div class="card">
                    <form #leaveForm="ngForm" (ngSubmit)="onSubmit()">
                        <div class="card-header  border-0">
                            <h4 class="card-title">Leave Form</h4>
                        </div>
                        <div class="card-body">

                            <div class="leave-types">
                                <!-- <div class="form-group" >
                        <label class="form-label"> Leave For</label>
                        <select  required   name="proxy"  class="form-control custom-select select2"  (change)="getisproxy($event)" >
                            <option label="Select" disabled selected></option>
                            <option value=0> Leave For Self</option>
                            <option value=1> Leave For Employee</option>
                        </select>
                    </div> -->
                                <div class="form-group row">
                                    <label for="inputName" class="w-100 "> Employee Name / ID</label>
                                    <div class="col-md-12 px-0">
                                        <input type="search" class="form-control" name="Employee Name" id="employeename"
                                            placeholder="Enter Employee Name / ID " [(ngModel)]="query"
                                            autocomplete="off" (input)="getEmployeeByNameOrId()"
                                            list="employeeMasterLucene" autofocus="autofocus">
                                        <datalist id="employeeMasterLucene">
                                            <option data-value={{emp.name}} *ngFor="let emp of employeeMasterLucene">
                                                {{emp.employeeid}}
                                            </option>
                                        </datalist>
                                    </div>
                                </div>
                            </div>

                            <div class="leave-types">
                                <div class="form-group">
                                    <label class="form-label">Leave Dates Type</label>
                                    <select required (ngModelChange)="onChangeDays($event)"
                                        [ngModel]="empLeaveInfo.halfFullIndic" name="leave"
                                        class="form-control custom-select select2" id="daterange-categories">
                                        <option label="Select" disabled></option>
                                        <option [value]="leaveDate.halfFullIndic"
                                            *ngFor="let leaveDate of leaveDatesList">{{leaveDate.leaveDay}}</option>
                                    </select>
                                </div>
                                <div class="leave-content active" id="single">
                                    <div class="form-group">
                                        <label class="form-label">Date Range:</label>
                                        <div class="input-group">
                                            <input [disabled]="true" required type="text" id="singledate"
                                                name="singledaterange" class="form-control"
                                                placeholder="select dates" />
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <i class="bx bx-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="leave-content" id="multiple">
                                    <div class="form-group">
                                        <label class="form-label">Date Range:</label>
                                        <div class="input-group">
                                            <input [disabled]="true" required type="text" id="multipledate"
                                                name="daterange" class="form-control" placeholder="select dates" />
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <i class="bx bx-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Leave Type</label>
                                    <select disabled required [ngModel]="empLeaveInfo.leaveType"
                                        (ngModelChange)="onChangeLeaveTypes($event)" name="projects"
                                        class="form-control custom-select select2" id="leavetypes">
                                        <option label="Select" disabled></option>
                                        <option [ngValue]="leave.leaveType" *ngFor="let leave of leaveTypesList">
                                            {{leave.leaveName}}</option>
                                    </select>
                                    <div class="d-flex justify-content-between">
                                        <div style="padding-top: 5px; padding-bottom: 5px ; padding-right: 10px;"
                                            id="leavetypesc"
                                            [style.display]="i.leaveType === empLeaveInfo.leaveType ? 'block':'none '"
                                            *ngFor="let i of leaveTypesList" class="font-weight-semibold">{{i.leaveName
                                            }}:

                                            <span
                                                class="badge badge-danger badge-pill ms-2">{{leaveBalance_Count}}</span>
                                        </div>
                                        <div style="padding-top :5px">
                                            <label class="mb-0 font-weight-semibold text-dark">Selected Days:</label>
                                            <span class="badge badge-danger badge-pill ms-2">{{totalLeaveCount}}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="form-label">Reason:</label>
                                    <textarea disabled id="reason" [(ngModel)]="empLeaveInfo.reason"
                                        class="form-control" rows="2"></textarea>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button [disabled]="!empLeaveInfo.reason.length" class="btn btn-success"
                                    type="submit">Submit</button>
                                <button type="button" (click)="onClear()" class="btn btn-danger ms-2">Clear</button>
    
                
                                </div>
                            </div>
                        
                    </form>
                </div>
            </div>
            <!-- <div class="col-xl-6 col-lg-12 col-md-12">
        <div class="card">
            <div class="p-0">
                <div class="card-header border-0">
                    <h4 class="card-title">Calendar</h4>
                </div>
                <div class="leave-calendar p-3 pt-0 mt-3"></div>
            </div>
        </div>
    </div> -->
            <!-- <div *ngIf="employeeLeaveName.length" class="col-xl-6 col-md-12 col-lg-12 h-100">
        <div class="card">
            <div class="card-header  border-0">
                <h4 class="card-title">Leave Overview</h4>
            </div>
            <div class="card-body">
                 
                 <div id="leavesoverview" class="mx-auto pt-2"></div> 
                <apx-chart *ngIf="showChart"
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [fill]="chartOptions.fill"
                    [dataLabels]="chartOptions.dataLabels"
                    [responsive]="chartOptions.responsive"
                ></apx-chart>  
            </div>
        </div>
    </div> -->
        </div>
    </div>
</div>