import { Component, OnInit } from '@angular/core';
import { ExpenseSubmission } from '../models/ExpenseSubmission';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { SessionService } from "../services";
import { Menu } from '../services/menu.Service';

@Component({
  selector: 'app-expense-approval',
  templateUrl: './expense-approval.component.html',
  styleUrls: ['./expense-approval.component.css']
})
export class ExpenseApprovalComponent implements OnInit {

  constructor(private expensemanagerService: ExpensemanagerService,private session: SessionService,private menuService: Menu) {
  }

  ngOnInit(): void {
    this.getExpenseList();
  }
  expenseSubmission: ExpenseSubmission = new ExpenseSubmission();
  expenseList: any[] = [];
  expensesubList: ExpenseSubmission[];
  id: number;
  client_name: any;
  date_of_expense: Date;
  expense_type_id: number;
  expenseType: any[] = [];
  amount: number;
  user_id: any;
  timestamp: any;
  status: any;
  fileToUpload: File | null = null;
  fileSavedPath: any;
  filename: any;
  selectAll: boolean = false;

  filteredExpenseList: any[] = [];
  filterTaskList: ExpenseSubmission[];
  name:any;
  employeeid:any;
  selectedList: any[] = [];
  userUniqueid: any;

  uniqueempid: string = this.session.getempid()

  approvalamount:any;
  approvalDetails: any[] = [];

  getExpenseList() {
    this.expensemanagerService.getApproveList(this.uniqueempid).subscribe(
      (data) => {
        console.log("Data available :", data);
        this.expenseList = data.expenseApproval;
        this.filteredExpenseList = data.expenseApproval;

        console.log("List of all the expenseApprove available :", this.expenseList);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  viewdocument(filename: string, path: string) {
    if (path === undefined) {
      path = filename;
    }
    console.log(" ")
    console.log("view document : ", path)
    this.expensemanagerService.viewdocument(path).subscribe(
      (response) => {
        console.log(`Our response : ${response}`)
        if (filename.includes('.pdf')) {
          var file = new Blob([response], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.includes('.jpg') || filename.includes('.jpeg')) {
          var file = new Blob([response], { type: 'image/jpeg' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } else if (filename.toLowerCase().includes('.png')) {
          var file = new Blob([response], { type: 'image/png' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        } 
        else {
          console.log("Other types are not recommended!");
        }
      },
      (error) => { },
    )
  }

  checkallbox(event: any): void {
    // Update the status of all checkboxes based on the Select All checkbox
    this.expenseList.forEach((exp) => {
      exp.checked = event.target.checked;
  
      // Add or remove from selectedList based on checked status
      if (exp.checked) {
        this.addToSelectedList(exp);
      } else {
        this.removeFromSelectedList(exp);
      }
    });
    console.log("expense list",this.expenseList);
    console.log("expense list",this.selectedList);
  }

  addToSelectedList(exp: any): void {
    // Check if the item is not already in the selectedList before adding
    if (!this.selectedList.includes(exp)) {
      this.selectedList.push(exp);
    }
  }
  
  removeFromSelectedList(exp: any): void {
    // Remove the item from the selectedList if it exists
    this.selectedList = this.selectedList.filter((item) => item !== exp);
  }
  
  check_info(exp: any, event: any): void {
    // Update the individual checkbox status
    exp.checked = event.target.checked;
    if (exp.checked) {
      this.addToSelectedList(exp);
    } else {
      this.removeFromSelectedList(exp);
    }
    console.log("expense list",exp.checked);
    console.log("expense list",exp);
    console.log("expense list",this.selectedList);
  }

  myDocumentDownload(path: any, filename: any) {
    console.log("path ", path);

    this.fileSavedPath = path;
    this.filename = filename;
    console.log("The File Saved Path is", this.fileSavedPath);
    this.downloadImage();
  }

  downloadImage() {
    console.log("Download Image is called");
    this.expensemanagerService.downloadFile(this.fileSavedPath).subscribe(
      (data) => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.filename;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      (error) => {
        console.log("Error occured in download file");
      }
    );
  }

    approvalhandler() {
    let flag = true
		if (this.selectedList.length === 0) {
			this.alertmessages("Please select item for Approval.", "danger")

			flag = false
			return;
		}
    this.expensemanagerService
        .approveList(this.selectedList, this.uniqueempid)
        .subscribe(
          (res) => {
            console.log("filteredExpenseList", this.selectedList);
            this.alertmessages("Successfully Saved ", "success");
            this.selectedList =[];
            this.getExpenseList();
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
  }

  rejecthandler() {
    let flag = true
		if (this.selectedList.length === 0) {
			this.alertmessages("Please select item for Approval.", "danger")

			flag = false
			return;
		}
    this.expensemanagerService
        .rejectList(this.selectedList)
        .subscribe(
          (res) => {
            console.log("filteredExpenseList", this.selectedList);
            this.alertmessages("Successfully Saved ", "success");
            this.getExpenseList();
            this.selectedList =[];
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
  }


  filterExpenseList(searchText: string) {
    console.log("filter employeename  ")

    if (this.expenseList.length != 0) {

      console.log("expenseList : ", this.expenseList)

      this.filteredExpenseList = this.expenseList.filter(expense =>
        expense.name?.toLowerCase().includes(searchText.toLowerCase())
      );

      console.log("expenseList : ", this.filteredExpenseList)
      // Update the filterTaskList with the filtered results
      this.filterTaskList = this.filteredExpenseList;
      console.log("filterTaskList : ", this.filterTaskList)

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }

    } else {
      console.log("documentsToBeApproved : ")
      // this.filteredEmployees = this.documentsToBeApproved.filter(employee =>
      //   employee.employeeName.toLowerCase().includes(searchText.toLowerCase())
      // );
      // Update the filterTaskList with the filtered results
      this.filterTaskList = this.filteredExpenseList;

      if (this.filterTaskList.length === 0) {
        this.alertmessages("No Result Found Please Try Again !  ", "danger");
      }
    }

  }

  approved_details(expid:any) {
    this.expensemanagerService.getApprovedDetails(expid).subscribe(
      data => {
        console.log("Approved Details  : ", data)
        this.approvalDetails = data.expenseApprovalDetails;
      },
      error => {
        console.log(error);
      }
    );
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
  <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  navigateExpenseStatus() {
		localStorage.setItem('navigateDashboard', 'true')
	  }

	  setMenuName(name: string) {
		this.menuService.setMenuName(name);
	  }
}
