<div class="app-content main-content">
    <div class="side-app main-container">
      <div class="alertcomp"></div>
      <!-- <div class="card-body px-0">
        <div class="page-leftheader my-3">
          <div class="page-title">Policy Submission</div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <form id="fcForm" class="form-horizontal form-label-left row" #policysubmissionform="ngForm">
  
                <div class="row col-md-12 col-sm-12 col-xs-12">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                  
                    <div class="form-group label-floating">
                      <label class="form-label">Policy Document Name</label>
                      <div>
                        <input type="text" name="file_Type" class="form-control col-md-12 col-xs-12" autocomplete="off"
                          placeholder="Policy Document Name" autofocus="true" required [(ngModel)]="fileType"
                          required  #file_Type="ngModel" [ngClass]="(!file_Type.valid && file_Type.touched) ? 'is-invalid' : ''"/>
                      </div>
                      <span class="help-text text-danger" *ngIf="(!file_Type.valid && file_Type.touched)" >Please Enter Policy Document Name</span>
                    </div>
                  </div>
  
                  <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group label-floating">
                      <label class="form-label">File Name</label>
                      <div>
                        <input type="date" class="form-control" name="date_of_expense" />
                      </div>
                      <span class="help-text text-danger">Please Enter
                        FileName </span>
                    </div>
                  </div> -->
  
                  <!-- <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label" for="expansetype">Expense Type</label>
                      <div class="w-100">
                        <select class="form-control col-md-12 col-xs-12" style="height: 38px"
                          data-style="btn btn-drpdwn btn-round" id="expansetype">
                        
                          <option>
                            select
                          </option>
                        </select>
                      </div>
                      <span class="help-text text-danger">Please Enter
                        Expense Type</span>
                    </div>
                  </div> -->
  
                  <!-- <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Amount</label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                          placeholder="Enter amount" name="enteredamount" autofocus="true" />
                      </div>
                      <span class="help-text text-danger">Please Enter
                        Amount</span>
                    </div>
                  </div> -->
  
  
                  <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group label-floating">
                      <label for="form-label" class="form-label">Attachment</label>
                      <input type="file" id="file" class="form-control col-md-12 col-xs-12" name="myFilename"
                        style="box-sizing: border-box" required="required" (change)="onFileSelected($event)"/>
                      <span class="help-text text-danger">Upload File</span>
                    </div>
                  </div> -->
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <div class="form-group label-floating">
                      <label for="form-label" class="form-label">Attachment</label>
                      <input type="file" id="file" class="form-control col-md-12 col-xs-12" name="myFilename"
                        style="box-sizing: border-box;height: 2.4rem" required="required" #filename="ngModel"
                        [(ngModel)]="selectedfile"
                        (change)="onFileSelected($event)" [ngClass]="(!filename.valid && filename.touched) ? 'is-invalid' : ''"/>
                      <span class="help-text text-danger" *ngIf="(!filename.valid && filename.touched)">Upload
                        File</span>
                    </div>
                  </div>
                </div>
  
                <div class="actionBar">
                  <div class="clearfix"></div>
                  <div class="pro-fab">
                    <button class="btn btn-success" type="button" (click)="submit()">
                      Save
                    </button>
                    <button class="btn btn-danger ms-2" type="button" (click)="clear()">
                      Clear
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card col-md-12 col-sm-12 col-xs-12">
        <div class="card-header border-bottom-0">
          <h3 class="card-title">List of Policy</h3>
        </div>
        <div class="card-body card1S p-0">
          <div class="table-responsive">
            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
              <thead class="tablerow">
                <tr class="rowcolors stickyatTop">
                  <th class="border-bottom-0 fs-7">Policy ID</th>
                  <th class="border-bottom-0 w-5 fs-7">File Type</th>
                  <th class="border-bottom-0 fs-7">File Name</th>
                  <th class="border-bottom-0 fs-7">Uploaded Date</th>
                  <th class="border-bottom-0 fs-7">Uploaded By</th>
                  <th class="border-bottom-0 fs-7">Status</th>
                  <th class="border-bottom-0 fs-7">Action</th>
                </tr>
              </thead>
              <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                <tr *ngFor="let pol of policyList; let i = index">
                  <td>{{ pol.id }}</td>
                  <td>{{ pol.fileType }}</td>
                  <td>{{ pol.fileName }}</td>
                  <td>{{ pol.timestamp| date: 'mediumDate'}}</td>
                  <td>{{ pol.userName }}</td>
                  <td>
                    <span *ngIf="pol.status === 'PENDING'"
                        class="badge badge-warning">{{pol.status}}</span>
                    <span *ngIf="pol.status === 'APPROVED'"
                        class="badge badge-success">{{pol.status}}</span>
                    <span *ngIf="pol.status === 'REJECTED'"
                        class="badge badge-danger">{{pol.status}}</span>
                </td>
                  <td>
                    <button class="btn btn-primary btn-icon btn-sm me-2"
                      (click)="myDocumentDownload(pol.filePath, pol.fileName)">
                      <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                    </button>
  
                    <button class="btn btn-primary btn-icon btn-sm me-2"
                      (click)="viewdocument(pol.filePath, pol.fileName)" style="width: 32px;">
                      <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                    </button>

                    <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete" title="Delete"
                      data-bs-target="#showAlertWarning" (click)="onDeleteHandler(pol.id)">
                      <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                    </button>
                  </td>
                </tr>
                <br><br><br>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Policy Submission data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deletePolicy()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>