<div class="alertcomp">

</div>

<app-consolidated-report [consolidatedReport]="consolidatedReport" [fromDate]="fromDate" [toDate]="toDate"
    [keyinempid]="keyinempid">
</app-consolidated-report>

<!-- printing report data here -->
<div class="row">
    <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
            <div class="card-header  border-0">
                <h4 class="card-title">Detailed Report</h4>
                <button type="button" class="btn btn-primary ms-auto" (click)="attendanceReportCSVDownload()">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
            </div>
            <div class="card-body">
                <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
                <div class="col-xs-12 mobile view">
                    <div class="scrollsearchresults" id="scrolllength">
                        <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                            <thead class="tablerow stickyatTop">
                                <tr class="rowcolors stickyatTop">
                                    <th class="border-bottom-0 w-5 fs-6 backblur fixedcolumn">Date</th>
                                    <th class="border-bottom-0 w-5 fs-6">First-In</th>
                                    <th class="border-bottom-0 w-5 fs-6">Last-Out</th>
                                    <th class="border-bottom-0 w-5 fs-6">Total Hours</th>
                                    <th class="border-bottom-0 w-5 fs-6">Approved Leave</th>
                                    <th class="border-bottom-0 w-5 fs-6">Odd Punch</th>
                                    <th class="border-bottom-0 w-5 fs-6">Extra Day</th>
                                    <th class="border-bottom-0 w-5 fs-6">Overtime</th>
                                    <!-- <th class="border-bottom-0 w-5 fs-6">Remark</th> -->
                                    <th class="border-bottom-0 w-5 fs-6">Status</th>
                                    <th class="border-bottom-0 w-5 fs-6">Location</th>
                                    <th class="border-bottom-0 w-5 fs-6"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let attendance of detailedReportData" [ngClass]="{'bg-warning-transparent' : attendance.annualHoliday === 1,
                'bg-info-transparent' : attendance.weeklyHolidays === 1}">

                                    <td class="fixedcolumn backgroundSW">{{attendance.date| date: 'mediumDate'}}</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.firstIN == undefined ? "--" :
                                        attendance.firstIN | date: 'shortTime' }}</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.lastOUT == undefined ? "--" :
                                        attendance.lastOUT| date: 'shortTime'}}</td>

                                    <!-- <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.totalSwipeHour == undefined ?
                                        attendance.totalSwipeHourStringFormate :
                                        attendance.totalSwipeHour }}</td> -->

                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ attendance.totalSwipeHourStringFormate ||
                                        attendance.totalSwipeHour || '--'}}</td>

                                    <!-- <td>
                                        {{ attendance.totalSwipeHourStringFormate ?
                                        (attendance.totalSwipeHourStringFormate + ':00' | date:'HH:mm:ss') :
                                        (attendance.totalSwipeHour | date:'HH:mm:ss') || '--' }}
                                    </td> -->


                                    <td data-th="contractclientName">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.leaveTaken === 0 ? "--" :
                                        attendance.leaveTaken }}
                                    </td>
                                    <td data-th="contractclientName">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.oddPunch === 0 ? "--" :
                                        attendance.oddPunch }}
                                    </td>
                                    <td data-th="contractclientName">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.extraDays === 0 ? "--" :
                                        attendance.extraDays }}
                                    </td>

                                    <td data-th="contractclientName">
                                        <span *ngIf="attendance.otHours > 0">
                                            {{ attendance.otHours }} Hours
                                        </span>

                                        <span *ngIf="attendance.otPieces > 0">
                                            {{ attendance.otPieces }} Pieces
                                        </span>

                                        <span *ngIf="!(attendance.otPieces > 0) && !(attendance.otHours > 0)">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--
                                        </span>
                                    </td>

                                    <!-- <td data-th="penalty">
                                         {{ selectedEmpObjforPenalty.remark!="" ? "1" : "--" }} 
                                          {{ selectedEmpObjforPenalty.remark.some(remark => attendance.date === currentDate) ? "1" : "" }} 
                                    </td> -->

                                    <td>
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.daysPresent === 0.5" class="badge bg-warning">
                                            Half Day
                                        </span>
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.daysPresent === 1" class="badge bg-info">
                                            Full Day
                                        </span>
                                        <span style="font-size: 13px; font-weight: 500; color: red;"
                                            *ngIf="attendance.oddPunch !== 0">
                                            Odd Punch
                                        </span>
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.leaveTaken !== 0" class="badge"
                                            [ngStyle]="getDynamicStyles(attendance)">
                                            Approved Leave | {{attendance.leaveType}}
                                        </span>
                                        <!-- attendance.daysPresent === 0 -->
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.daysPresent !==1 && attendance.weeklyHolidays !== 1 && attendance.annualHoliday !== 1 
                                            && attendance.oddPunch === 0 && attendance.afterCurrentDate === 0 && attendance.leaveTaken === 0 && attendance.extraDays===0"
                                            class="badge btn-danger">
                                            Absent
                                        </span>

                                        <!-- <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.weeklyHolidays === 1 && attendance.afterCurrentDate === 0"
                                            class="badge bg-orange-dark">
                                            Weekly Holiday
                                        </span> -->
                                        <!-- show weekly for future date or comment below and uncommet above .. -->
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.weeklyHolidays === 1" class="badge"
                                            [ngStyle]="getDynamicStyles(attendance)">
                                            Weekly Holiday
                                        </span>
                                        <!-- <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.annualHoliday === 1 && attendance.afterCurrentDate === 0"
                                            class="badge bg-blue-dark">
                                            Annual Holiday
                                        </span> -->
                                        <!-- show annualHoliday for future date or comment below and uncommet above .. -->
                                        <span style="font-size: 13px; font-weight: 500;"
                                            *ngIf="attendance.annualHoliday === 1 " class="badge"
                                            [ngStyle]="getDynamicStyles(attendance)">
                                            Annual Holiday
                                        </span>
                                    </td>
                                    <td>
                                        <!-- location can be reused  -->
                                        <!-- <button class="btn btn-primary btn-icon btn-sm me-2"
                                            (click)="openTabLocation(attendance.latitude,attendance.longitude)">
                                            <i class="si si-location-pin" data-bs-toggle="tooltip"
                                                data-original-title="location">
                                            </i>
                                        </button> -->

                                        <a class="btn btn-success plr" data-bs-toggle="modal"
                                            href="#additionalModalSwipe" (click)="get_Attendence_info(attendance)"
                                            role="button"> <i class="fa fa-eye" data-bs-toggle="tooltip"
                                                data-original-title="download"></i>
                                        </a>

                                    </td>
                                    <td data-th="Action" class="td_session">
                                        <button class="btn btn-primary btn-icon btn-sm me-2" style="width: 32px;"
                                            data-bs-placement="top" (click)="penaltyPopup(attendance)">
                                            <i class="fa fa-envelope" data-bs-toggle="tooltip"
                                                data-original-title="penaltyremark"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- swipe preview  -->
<div class="modal fade" id="additionalModalSwipe" aria-hidden="true" aria-labelledby="additionalModalSwipeLabel"
    tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Swipe Info as on
                </h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow">
                                    <tr class="rowcolors">
                                        <th class="border-bottom-0">Sl No</th>
                                        <th class="border-bottom-0">Clock IN/OUT</th>
                                        <th class="border-bottom-0">Time</th>
                                        <th class="border-bottom-0"></th>
                                        <th class="border-bottom-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>{{ swipe.swipetype === 1 ? "IN" :"OUT" }}</td>
                                        <td>{{ swipe.swipetime| date: 'shortTime' }}</td>
                                        <td>
                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                (click)="openTabLocation(swipe.latitude,swipe.longitude)">
                                                <i class="si si-location-pin" data-bs-toggle="tooltip"
                                                    data-original-title="location">
                                                </i>
                                            </button>
                                        </td>
                                        <td>
                                            <button class="btn btn-success plr"
                                                *ngIf="swipe.capturedPath!= undefined && swipe.capturedPath!=null"
                                                data-bs-target="#capturePreview" (click)="getPreview(swipe)"
                                                data-bs-toggle="modal" data-bs-dismiss="modal">
                                                <i class="si si-picture" data-bs-toggle="tooltip"
                                                    data-original-title="download"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- capture preview  -->
<div class="modal fade" id="capturePreview" aria-hidden="true" aria-labelledby="capturePreviewLabel" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="capturePreviewLabel" style="font-size: 20px;">Preview</h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="modal-body">
                        <img width="100%" height="auto" alt="img"
                            [src]="url || './../../../assets/template_assests/images/users/1.jpg'">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" data-bs-target="#additionalModalSwipe" data-bs-toggle="modal"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- penalty view  -->
<div class="modal fade" id="plusModal1" tabindex="-1" aria-labelledby="additionalModalLabel2" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">
                </h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors stickyatTop">
                                        <th class="border-bottom-0 w-5 fs-6" style="color:white">Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container>

                                        <tr>

                                            <td>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ selectedEmpObjforPenalty.remarks}}
                                            </td>

                                        </tr>

                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>